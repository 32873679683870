import { FirebaseAppFirestore } from '@/app/shared/firebase/firebase-app';
import { getCurrentUser } from '@/app/shared/firebase/firebase-user';
import firebaseNames from '@/app/shared/utilities/firebase-names';
import { AdSpace, AdSPaceScreenSaver, CampaignMedia, ScreenCluster, DefaultScreenSaver } from '@/app/shared/utilities/static-types';
import { not } from '@/app/shared/utilities/helper-functions';
import { isAdminUserAction } from '@globalActions/UserRolesActions';
import { WriteBatch } from '@firebase/firestore-types';

/**
 * Create an Ad Space for current user.
 * @param adSpace Input adSpace
 */
export const createAdSpaceAction = async (adSpace: AdSpace) => {
  const currentUser = await getCurrentUser();
  const adSpaceRef = FirebaseAppFirestore
    .collection(firebaseNames.AD_SPACES.VAL)
    .doc();
  const adSpaceId = adSpaceRef.id;
  adSpace.ID = adSpaceId;
  const isAdminUser = await isAdminUserAction();
  if (not(isAdminUser) || not(adSpace.SPACE_OWNER_ID)) {
    adSpace.SPACE_OWNER_ID = currentUser!.uid;
  }
  await adSpaceRef.set(adSpace);
  return adSpaceId;
};

/**
 * Update an Ad Space for current user.
 * @param adSpace Input adSpace
 */
export const updateAdSpaceAction = async (adSpace: AdSpace) => {
  const { ID } = adSpace;
  await FirebaseAppFirestore
    .collection(firebaseNames.AD_SPACES.VAL)
    .doc(ID)
    .update(adSpace);
  if (adSpace.SCREEN_CLUSTER_REF) {
    await adSpace.SCREEN_CLUSTER_REF.update({ PRICE: adSpace.BASE_PRICE } as ScreenCluster);
  }
};

const batchUpdateAdSpaceScreenSaversAction = async (batch: WriteBatch, adSpaceId: string, adSpaceScreenSavers: AdSPaceScreenSaver[]) => {
  batch.update(FirebaseAppFirestore
    .collection(firebaseNames.AD_SPACES.VAL)
    .doc(adSpaceId), { SCREEN_SAVERS: adSpaceScreenSavers });

};
export const applyToAllAdSpacesAction = (batch: WriteBatch,
                                         adSpace: AdSpace,
                                         screenSaver: DefaultScreenSaver,
                                         media: CampaignMedia) => {
  const adSpaceScreenSaver =
    adSpace.SCREEN_SAVERS &&
    adSpace.SCREEN_SAVERS.find((adSpaceScreenSaverItem) =>
      adSpaceScreenSaverItem.MEDIA_FILE.MEDIA_FILE === media.MEDIA_FILE);
  if (adSpaceScreenSaver) {
    if (adSpaceScreenSaver.ACTIVE !== screenSaver.ACTIVE) {
      adSpace.SCREEN_SAVERS = adSpace.SCREEN_SAVERS.map((adSpaceScreenSaverItem) => {
          if (adSpaceScreenSaverItem.MEDIA_FILE.MEDIA_FILE === media.MEDIA_FILE) {
            adSpaceScreenSaverItem.ACTIVE = screenSaver.ACTIVE;
          }
          return adSpaceScreenSaverItem;
        },
      );
      return batchUpdateAdSpaceScreenSaversAction(batch, adSpace.ID, adSpace.SCREEN_SAVERS);
    }
    return;
  }
  if (!adSpace.SCREEN_SAVERS) {
    adSpace.SCREEN_SAVERS = [];
  }
  adSpace.SCREEN_SAVERS = adSpace.SCREEN_SAVERS.concat([
    {
      ORDER: adSpace.SCREEN_SAVERS.length + 1,
      ACTIVE: screenSaver.ACTIVE,
      MEDIA_FILE: media,
    },
  ]);
  return batchUpdateAdSpaceScreenSaversAction(batch, adSpace.ID, adSpace.SCREEN_SAVERS);

};
export const deleteScreenSaversRelatedToAdSpacesAction = (batch: WriteBatch,
                                                          adSpace: AdSpace,
                                                          screenSaver: DefaultScreenSaver,
                                                          media: CampaignMedia) => {
  const adSpaceScreenSaver =
    adSpace.SCREEN_SAVERS &&
    adSpace.SCREEN_SAVERS.filter((adSpaceScreenSaverItem) =>
      adSpaceScreenSaverItem.MEDIA_FILE.MEDIA_FILE !== media.MEDIA_FILE,
    );
  if (
    adSpaceScreenSaver &&
    adSpaceScreenSaver.length !== adSpace.SCREEN_SAVERS.length
  ) {
    adSpace.SCREEN_SAVERS = adSpaceScreenSaver.map((adSpaceScreenSaverItem, index) => {
      adSpaceScreenSaverItem.ORDER = index + 1;
      return adSpaceScreenSaverItem;
    });
    return batchUpdateAdSpaceScreenSaversAction(batch, adSpace.ID, adSpace.SCREEN_SAVERS);
  }
};
/**
 * Load Ad Space for current user.
 */
export const loadUserAdSpacesAction = async (): Promise<AdSpace[]> => {
  const currentUser = await getCurrentUser();
  const userId = currentUser!.uid;
  const adSpacesSnap = await FirebaseAppFirestore
    .collection(firebaseNames.AD_SPACES.VAL)
    .where('SPACE_OWNER_ID', '==', userId)
    .get();

  if (adSpacesSnap.empty) {
    return [];
  }

  return adSpacesSnap.docs.map((doc) => doc.data() as AdSpace);
};

/**
 * Load one Ad Space by id for current user.
 */
export const loadAdSpaceByIdAction = async (loadedId: string): Promise<AdSpace> => {
  const adSpaceSnap = await FirebaseAppFirestore
    .collection(firebaseNames.AD_SPACES.VAL)
    .doc(loadedId)
    .get();
  return adSpaceSnap.data() as AdSpace;
};

/**
 * Load All Ad Spaces
 */
export const loadAllAdSpacesAction = async (): Promise<AdSpace[]> => {
  const adSpacesSnap = await FirebaseAppFirestore
    .collection(firebaseNames.AD_SPACES.VAL)
    .get();

  if (adSpacesSnap.empty) {
    return [];
  }

  return adSpacesSnap.docs.map((doc) => doc.data() as AdSpace);
};

export const toggleVisibleOnPlatformAction = async (adSpace: AdSpace) => {
  return FirebaseAppFirestore.collection(firebaseNames.AD_SPACES.VAL)
    .doc(adSpace.ID)
    .update({ IS_ONLINE: !adSpace.IS_ONLINE });
};
