import { RouteConfig } from 'vue-router';

import { AdminRoutes } from '@/app/shared/utilities/routes-names';
import { adminPagesGuard } from '@admin/config/admin-nav-guards';
import { userRolesPageGuard } from '@/app/shared/config/roles-guard';
import { USER_ROLES } from '@/app/shared/utilities/object-factory';
const AdvertisersCampaignMain = () => import('@adminCampaigns/screens/CampaignsMain/AdvertisersCampaignMain.vue');
const ApprovedCampaigns = () => import('@adminCampaigns/screens/ApprovedCampaigns/ApprovedCampaigns.vue');
const AllCampaigns = () => import('@adminCampaigns/screens/AllCampaigns/AllCampaigns.vue');
const PendingAdminApprovalCampaigns = () => import('@adminCampaigns/screens/PendingAdminApprovalCampaigns/PendingAdminApprovalCampaigns.vue');
const AdvertiserUserList = () => import('@adminAdvertisers/screens/AdvertiserUserList/AdvertiserUserList.vue');
const PendingStoreOwnerApprovalCampaigns = () => import('@adminCampaigns/screens/PendingStoreOwnerApprovalCampaigns/PendingStoreOwnerApprovalCampaigns.vue');
const AdminCampaignReview = () => import('@adminCampaigns/screens/CampaignReview/AdminCampaignReview.vue');
const AdvertisersProfilesMain = () => import('@adminProfiles/screens/ProfilesMain/AdvertisersProfilesMain.vue');
const ApprovedProfiles = () => import('@adminProfiles/screens/ApprovedProfiles/ApprovedProfiles.vue');
const PendingProfilesHome = () => import('@adminProfiles/screens/PendingProfiles/screens/PendingProfilesHome/PendingProfilesHome.vue');
const PendingProfilesView = () => import('@adminProfiles/screens/PendingProfiles/screens/PendingProfilesView/PendingProfilesView.vue');

export default [
  {
    path: 'advertisers/campaigns',
    component: AdvertisersCampaignMain,
    children: [
      {
        beforeEnter: adminPagesGuard,
        ...AdminRoutes.ADVERTISER_ALL_CAMPAIGNS,
        path: 'all',
        component: AllCampaigns,
      },
      {
        beforeEnter: adminPagesGuard,
        ...AdminRoutes.ADVERTISER_PENDING_ADMIN_CAMPAIGNS,
        path: 'pending-admin-approval',
        component: PendingAdminApprovalCampaigns,
      },
      {
        beforeEnter: userRolesPageGuard([USER_ROLES.ADMIN, USER_ROLES.SALES]),
        ...AdminRoutes.ADVERTISER_USER_LIST,
        path: 'user-list',
        component: AdvertiserUserList,
      },
      {
        beforeEnter: adminPagesGuard,
        ...AdminRoutes.ADVERTISER_PENDING_STORE_OWNER_CAMPAIGNS,
        path: 'pending-store-owner-approval',
        component: PendingStoreOwnerApprovalCampaigns,
      },
      {
        beforeEnter: adminPagesGuard,
        ...AdminRoutes.ADVERTISER_CAMPAIGN_REVIEW,
        path: 'review',
        component: AdminCampaignReview,
      },
    ],
  },
  {
    path: 'advertisers/profiles',
    component: AdvertisersProfilesMain,
    children: [
      {
        beforeEnter: adminPagesGuard,
        ...AdminRoutes.APPROVED_PROFILES,
        path: 'approved',
        component: ApprovedProfiles,
      },
      {
        beforeEnter: adminPagesGuard,
        ...AdminRoutes.PENDING_PROFILES,
        path: 'pending',
        component: PendingProfilesHome,
      },
      {
        beforeEnter: adminPagesGuard,
        ...AdminRoutes.PENDING_PROFILES_VIEW,
        path: 'pending/view',
        component: PendingProfilesView,
      },
    ],
  },
] as RouteConfig[];
