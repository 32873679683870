





































import { Component, Vue } from 'vue-property-decorator';
import {
  loadScreenSaversAction,
  updateScreenSaver,
} from '@adminSchedules/shared/actions';
import FormComponent from '@/app/shared/components/FormComponent.vue';
import CreateScreenSaver from '@/app/screens/App/screens/Admin/screens/Schedules/screens/ScreenSaver/components/CreateScreenSaver/CreateScreenSaver.vue';
import ScreenSaverList from '@/app/screens/App/screens/Admin/screens/Schedules/screens/ScreenSaver/components/ScreenSaverList/ScreenSaverList.vue';
import {
  DefaultScreenSaver,
} from '@/app/shared/utilities/static-types';

@Component({
  components: {
    FormComponent,
    CreateScreenSaver,
    ScreenSaverList,
  },
})
export default class ScreenSaver extends Vue {
  public showCreateScreenSaver = false;
  public screenSavers: DefaultScreenSaver[] = [];
  public updateListFunction!: () => void;

  public activeChanged(screenSaverId: string) {
    updateScreenSaver(
      this.screenSavers.find(
        (screenSaver) => screenSaver.ID === screenSaverId,
      ) as DefaultScreenSaver,
    );
  }

  public setUpdateListFunction(updateListFunction: () => void) {
    this.updateListFunction = updateListFunction;
      }

  public async updateList() {
    await this.loadScreenSavers();
    this.updateListFunction()
    ;
  }

  public async loadScreenSavers() {
    this.screenSavers = await loadScreenSaversAction();
  }
}
