











































import { Component, Vue } from 'vue-property-decorator';
import { AdSpace, AuthorizedUser, StoreAccount } from '@/app/shared/utilities/static-types';
import ClickableAdspaceItem from './components/ClickableAdspaceItem.vue';
import AdSpacesFilters from './components/AdSpacesFilters.vue';
import { AdminRoutes } from '@/app/shared/utilities/routes-names';
import AdspacesList from '@userApp/shared/components/ListView.vue';
import { loadAllAdSpacesAction, loadUserAdSpacesAction, toggleVisibleOnPlatformAction } from '@adSpaces/shared/actions';
import { isAdminUserAction, isStoreOwnerAction } from '@globalActions/UserRolesActions';
import { getStoresAction } from '@globalActions/DevicesActions';
import { cloneDeep } from 'lodash';

@Component({
  components: {
    AdspacesList,
    ClickableAdspaceItem,
    AdSpacesFilters,
  },
})
export default class StoreAdSpaces extends Vue {
  public loading = false;
  public newAdSpaceRoute = AdminRoutes.CREATE_STORE_AD_SPACE;
  public editAdSpaceRoute = AdminRoutes.EDIT_STORE_AD_SPACE;
  public adSpaces: AdSpace[] = [];
  public clonedAdSpaces: AdSpace[] = [];
  public filteredAdSpaces: AdSpace[] = [];
  public storeAccountsList: StoreAccount[] = [];
  public storeAccountsMap: Record<NonNullable<StoreAccount['UID']>,  StoreAccount> = {};
  public isAdminUser = false;
  public isStoreOwner = false;
  public toggleVisibilityLoading = false;

  public get adSpaceSortedAscByIsOnlineList() {
    return this.filteredAdSpaces.sort((a, b) => {
      if (b.IS_ONLINE) {
        return 1;
      }
      return -1;
    });
  }

  public async created() {
    try {
      this.loading = true;
      this.isAdminUser = await isAdminUserAction();
      this.isStoreOwner = await isStoreOwnerAction();
      if (this.isAdminUser) {
        this.adSpaces = await loadAllAdSpacesAction();
        this.storeAccountsList = await getStoresAction();
        this.storeAccountsMap = this.storeAccountsList.reduce((map, store) => {
          return {
            ...map,
            [store.UID!]: store,
          };
        }, {});
      } else if (this.isStoreOwner) {
        this.adSpaces = await loadUserAdSpacesAction();
      }
    } finally {
      if (this.adSpaces.length) {
        this.clonedAdSpaces = cloneDeep(this.adSpaces);
      }
      this.loading = false;
    }
  }

  public async toggleVisibleOnPlatform(adSpace: AdSpace) {
    try {
      this.toggleVisibilityLoading = true;
      await toggleVisibleOnPlatformAction(adSpace);

      const itemIndex = this.filteredAdSpaces.findIndex((adSpaceItem) => adSpaceItem.ID === adSpace.ID);
      if (itemIndex !== -1) {

        this.filteredAdSpaces[itemIndex].IS_ONLINE = !this.filteredAdSpaces[itemIndex].IS_ONLINE;
        this.clonedAdSpaces[itemIndex].IS_ONLINE = !this.clonedAdSpaces[itemIndex].IS_ONLINE;
      }
    } finally {
      this.toggleVisibilityLoading = false;
    }
  }
  public adSpaceStoreName(adSpace: AdSpace) {
    const storeName = this.storeAccountsMap[adSpace.SPACE_OWNER_ID]
            ? this.storeAccountsMap[adSpace.SPACE_OWNER_ID].STORE_NAME
            : '';
    return `<b>${storeName}</b>`;
  }

  public updateFilter({ storeOwnerFilter, typeFilter, cityFilter }: {
    storeOwnerFilter: Array<AuthorizedUser['UID']>,
    typeFilter: Array<AdSpace['TYPE']['VAL']>,
    cityFilter: Array<AdSpace['CITY']['GEONAME_ID']>,
  }) {
    const storeOwnerSet = new Set(storeOwnerFilter);
    const adSpaceTypeSet = new Set(typeFilter);
    const adSpaceCitySet = new Set(cityFilter);
    this.filteredAdSpaces = this.clonedAdSpaces.filter((adSpace) => {
      return (storeOwnerSet.size === 0 || storeOwnerSet.has(adSpace.SPACE_OWNER_ID)) &&
              (adSpaceTypeSet.size === 0 || adSpaceTypeSet.has(adSpace.TYPE.VAL)) &&
              (adSpaceCitySet.size === 0 || adSpaceCitySet.has(adSpace.CITY.GEONAME_ID));
    });
  }
}
