import { render, staticRenderFns } from "./PendingScreensView.vue?vue&type=template&id=aa1b0324&scoped=true&"
import script from "./PendingScreensView.vue?vue&type=script&lang=ts&"
export * from "./PendingScreensView.vue?vue&type=script&lang=ts&"
import style0 from "./PendingScreensView.vue?vue&type=style&index=0&id=aa1b0324&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "aa1b0324",
  null
  
)

export default component.exports