import { getCurrentUser } from '../firebase/firebase-user';
import { FirebaseAppFirestore } from '../firebase/firebase-app';
import { MyDevice, StoreAccount } from '../utilities/static-types';

import firebaseNames from '../utilities/firebase-names';





/**
 * Gets a Device by its ID
 * @param deviceId Device ID
 */
export const getDeviceByIdAction = async (deviceId: string) => {
  const currentUser = await getCurrentUser();
  const deviceSnap = await FirebaseAppFirestore
    .collection(firebaseNames.DEVICES.VAL)
    .doc(currentUser!.uid)
    .collection(firebaseNames.DEVICES.MY_DEVICES)
    .doc(deviceId)
    .get();
  return deviceSnap.data() as MyDevice;
};

/**
 * Get a list of system's stores.
 */
export const getStoresAction = async () => {
  const storesSnap = await FirebaseAppFirestore
    .collection(firebaseNames.STORES)
    .get();
  return storesSnap.docs.map((doc) => doc.data() as StoreAccount);
};

/**
 * Get store by ID.
 * @param storeId
 */
export const getStoreByIdAction = async (storeId: string) => {
  const storesSnap = await FirebaseAppFirestore
    .collection(firebaseNames.STORES)
    .doc(storeId)
    .get();
  return storesSnap.data() as StoreAccount;
};
