import geonamesData from '@/app/shared/resources/geonames.json';

import {
  City,
  Region,
  CityRecord,
  Country,
  GeoNamesData,
  GeoNamesCountry,
  GeoNamesRegion,
  GeoNamesCity,
} from './static-types';

// Cache objects
const citiesCache: Record<string, City[]> = {};
let cityRecordsCache: CityRecord | null = null;

/**
 * Transforms geonames.json data into a lookup object keyed by city geonameId
 * @returns Object with city geonameIds as keys and city/region info as values
 */
export function transformGeonamesToCityRecords(): CityRecord {
  if (cityRecordsCache) {
    return cityRecordsCache;
  }

  const typedGeonamesData = geonamesData as GeoNamesData;
  cityRecordsCache = typedGeonamesData.countries.reduce<CityRecord>((records, country) => {
    const result = { ...records };

    // Handle countries that have regions (e.g., states/provinces)
    if (country.regions) {
      country.regions.forEach((region) => {
        region.cities.forEach((city) => {
          result[city.geonameId] = {
            city: {
              GEONAME_ID: city.geonameId,
              DISPLAY_NAME: { en: city.name.en, ar: city.name.ar },
              POPULATION: city.population,
            },
            region: {
              GEONAME_ID: region.geonameId,
              DISPLAY_NAME: { en: region.name.en, ar: region.name.ar },
              POPULATION: region.population,
            },
          };
        });
      });
    }

    // Handle countries that have cities directly without regions
    if (country.cities) {
      country.cities.forEach((city) => {
        result[city.geonameId] = {
          city: {
            GEONAME_ID: city.geonameId,
            DISPLAY_NAME: { en: city.name.en, ar: city.name.ar },
            POPULATION: city.population,
          },
          region: {
            GEONAME_ID: country.geonameId,
            DISPLAY_NAME: { en: country.countryName, ar: country.countryName },
            POPULATION: undefined,
          },
        };
      });
    }

    return result;
  }, {});

  return cityRecordsCache!;
}

/**
 * Process cities data for a specific country
 * @param countryData The country to process
 * @returns Array of cities
 */
export function getCitiesByCountryCodeAction(countryData: Country): City[] {
  const countryCode = countryData.COUNTRY_CODE;
  // Return cached data if available
  if (citiesCache[countryCode]) {
    return citiesCache[countryCode];
  }

  const typedGeonamesData = geonamesData as GeoNamesData;
  const country = typedGeonamesData.countries.find((c: GeoNamesCountry) => c.countryCode === countryCode);
  if (!country) {
    return [];
  }

  let cities: City[];

  if (country.regions) {
    cities = country.regions.reduce((acc: City[], region: GeoNamesRegion) => {
      const regionCities = region.cities.map((city: GeoNamesCity) => ({
        GEONAME_ID: city.geonameId,
        DISPLAY_NAME: city.name,
        POPULATION: city.population,
      }));
      return [...acc, ...regionCities];
    }, []);
  } else if (country.cities) {
    cities = country.cities.map((city: GeoNamesCity) => ({
      GEONAME_ID: city.geonameId,
      DISPLAY_NAME: city.name,
      POPULATION: city.population,
    }));
  } else {
    cities = [];
  }

  // Cache the processed data
  citiesCache[countryCode] = cities;
  return cities;
}

/**
 * Get region information for a city by its geonameId
 * @param cityGeonameId The geonameId of the city
 * @returns The region information or null if not found
 */
export function getRegionByCityGeonameId(cityGeonameId: string): Region | null {
  const cityRecord = transformGeonamesToCityRecords()[cityGeonameId];
  return cityRecord ? cityRecord.region : null;
}

/**
 * @description
 * Get List of supported countries using GeoNames API.
 */
export const getCountriesAction = (): Country[] => {
  const typedGeonamesData = geonamesData as GeoNamesData;

  return typedGeonamesData.countries
    .map((country: GeoNamesCountry) => ({
      DISPLAY_NAME: country.countryName,
      GEONAME_ID: country.geonameId,
      COUNTRY_CODE: country.countryCode,
    }));
};
