
























import { Component, Prop, Vue } from 'vue-property-decorator';
import { DefaultScreenSaver } from '@/app/shared/utilities/static-types';
import PreviewThumbnail from '@campaigns/shared/components/PreviewThumbnail.vue';

@Component({
  name: 'ViewScreenSaver',
  components: {
    PreviewThumbnail,
  },
})
export default class ViewScreenSaver extends Vue {
  @Prop() public screenSaver!: DefaultScreenSaver;
  @Prop() public showMedia!: boolean;
  public closeEvent = 'update:show-media';

  get showMediaValue() {
    return this.showMedia;
  }

  set showMediaValue(showMedia) {
    this.$emit(this.closeEvent, showMedia);
  }
}
