







































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { AdSpace, StoreAccount } from '@/app/shared/utilities/static-types';
import { STORE_TYPES_ICON } from '@/app/shared/utilities/object-factory';

@Component({
  components: {},
})
export default class AdSpacesFilters extends Vue {
  @Prop({ required: true }) public adSpaces!: AdSpace[];
  @Prop({ required: true }) public storeAccountsList!: StoreAccount[];
  public storeOwnerFilter: StoreAccount[] = [];
  public cityFilter: Array<AdSpace['CITY']> = [];
  public typeFilter: Array<AdSpace['TYPE']> = [];
  public citiesMap: Record<AdSpace['CITY']['GEONAME_ID'], AdSpace['CITY']> = {};
  public adSpaceTypeMap: Record<AdSpace['TYPE']['VAL'], AdSpace['TYPE']> = {};
  public storeTypesIcons = STORE_TYPES_ICON;

  public get cities() {
    return Object.values(this.citiesMap);
  }

  public get adSpaceTypes() {
    return Object.values(this.adSpaceTypeMap);
  }
  public resetFilters() {
    this.storeOwnerFilter = [];
    this.typeFilter = [];
    this.cityFilter = [];
    this.$emit('filter-updated', {
      storeOwnerFilter: [],
      typeFilter: [],
      cityFilter: [],
    });
  }

 public updated() {
    this.$emit('filter-updated', {
      storeOwnerFilter: this.storeOwnerFilter.map((storeOwners) => storeOwners.UID),
      typeFilter: this.typeFilter.map((adSpaceType) => adSpaceType.VAL),
      cityFilter: this.cityFilter.map((adSpaceCity) => adSpaceCity.GEONAME_ID),
    });
  }

  @Watch('adSpaces.length')
  public adSpacesChanged() {
    this.adSpaceTypeMap = this.adSpaces.reduce((adSpaces, adSpace) => ({
      ...adSpaces,
      [adSpace.TYPE.VAL]: adSpace.TYPE,
    }), {});
    this.citiesMap = this.adSpaces.reduce((adSpaces, adSpace) => ({
      ...adSpaces,
      [adSpace.CITY.GEONAME_ID]: adSpace.CITY,
    }), {});
  }
}
