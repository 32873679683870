






























































































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator';

import {
  AdSpace, AdSPaceScreenSaver, AdSpacePriceControl,
  AdSpaceScreensPosition,
  City,
  Country,
  Media,
  MediaType,
  Nullable,
  StoreAccount,
  RegulatorAccount,
  Region,
  } from '@/app/shared/utilities/static-types';
import { STORE_PRICE_CONTROL, STORE_TYPES } from '@/app/shared/utilities/object-factory';
import {
  getStoresAction,
} from '@globalActions/DevicesActions';
import {
  getCitiesByCountryCodeAction,
  getCountriesAction,
} from '@/app/shared/utilities/geonames-transformer';
import { getRegionByCityGeonameId } from '@/app/shared/utilities/geonames-transformer';
import rules from '@/app/shared/validation-rules';
import routesNames, { AdminRoutes } from '@/app/shared/utilities/routes-names';
import FormComponent from '@/app/shared/components/FormComponent.vue';
import PopupMessage from '@/app/shared/components/PopupMessage.vue';
import { createAdSpaceAction, updateAdSpaceAction } from '@adSpaces/shared/actions';
import AdSpaceMediaUpload from '@adSpaces/screens/StoreOwner/screens/components/AdSpaceMediaUpload.vue';
import CreateAdSpaceScreenSaver from './Components/CreateAdSpaceScreenSaver/CreateAdSpaceScreenSaver.vue';
import { isAdminUserAction, isStoreOwnerAction } from '@globalActions/UserRolesActions';
import * as actions from '@userApp/shared/actions';
import firebase from 'firebase';
import storage = firebase.storage;
import AdSpaceScreenSaverList from '@adSpaces/screens/StoreOwner/screens/AdSpaceNew/Components/AdSpaceScreenSaverList/AdSpaceScreenSaverList.vue';
import { loadRegulators } from '@adminStores/shared/actions';

@Component({
  components: {
    AdSpaceMediaUpload,
    FormComponent,
    PopupMessage,
    CreateAdSpaceScreenSaver,
    AdSpaceScreenSaverList,
  },
  data: (vm: any) => ({
    requiredRules: [rules.required(vm.$i18n.t('field_required'))],
    adSpaceScreensPositions: Object.values(AdSpaceScreensPosition).map((val) => ({
      VAL: val,
      TEXT:  vm.$i18n.t(val),
    })),
  }),
})
export default class AdSpaceNew extends Vue {
  public userAdSpacesRoute = routesNames.AD_SPACES;
  public allAdSpacesRoute = AdminRoutes.ALL_AD_SPACES;
  public storeTypes = STORE_TYPES;
  public storePriceControl = STORE_PRICE_CONTROL;
  public countryArray: Country[] = [];
  public cityArray: City[] = [];
  public showCreateAdSpaceScreenSaver = false;
  public regulators: RegulatorAccount[] = [];
  public regulator: RegulatorAccount | null = null;
  public adSpace: AdSpace = {
    NAME: '',
    TYPE: {
      DISPLAY_NAME: 'Super Market',
      VAL: 'super-market',
    },
    PRICE_CONTROL: AdSpacePriceControl.BY_SHASHA,
    COORDINATES: {},
    SCREENS_RESOLUTION: {
      UNIT: 'px',
      WIDTH: 1920,
      HEIGHT: 1080,
    },
    SCREENS_POSITION: AdSpaceScreensPosition.INDOOR,
    SCREEN_SAVERS: [] as AdSPaceScreenSaver[],
  } as AdSpace;

  public selectedMediaFiles: File[] = [];

  public storeAccounts: StoreAccount[] = [];

  public isAdminUser = false;
  public isStoreOwner = false;

  public showConfirmationMessage: (() => Promise<any>) | null = null;

  public loading = false;
  public uploading = false;

  public get countries(): Country[] {
    return this.countryArray!.map(
      (country) =>
        ({
          ...country,
          TEXT: this.$t(`countries.${country.DISPLAY_NAME}`).toString(),
        } as Country),
    );
  }

  public get cities(): City[] {
    return this.cityArray!.map(
      (city) =>
        ({
          ...city,
          TEXT: this.$t(`cities.${city.DISPLAY_NAME.en}`).toString(),
        } as City),
    );
  }

  public async loadData() {
    this.loading = true;
    this.loadCountries();
    this.isAdminUser = await isAdminUserAction();
    this.isStoreOwner = await isStoreOwnerAction();
    this.storeAccounts = await getStoresAction();
    this.regulators = await loadRegulators();
    this.loading = false;
  }

  public loadCountries() {
    this.countryArray = getCountriesAction();
  }

  public loadCities(country: Country) {
    this.cityArray = getCitiesByCountryCodeAction(country);
  }

  public get selectedStoreOwner(): Nullable<StoreAccount> {
    return this.storeAccounts.find((store) => store.UID === this.adSpace.SPACE_OWNER_ID);
  }
  public updateRegulator(regulatorId: string) {
    return this.regulator = this.regulators.find((regulator) => regulator.UID === regulatorId) || null;
  }
  public async createAdSpace() {
    await this.showConfirmationMessage!();
    if (this.selectedStoreOwner!.CONTRACT_TYPE) {
      this.adSpace.CONTRACT_TYPE = this.selectedStoreOwner!.CONTRACT_TYPE;
    }
    if (this.selectedStoreOwner!.BRAND_NAME) {
      this.adSpace.BRAND_NAME = this.selectedStoreOwner!.BRAND_NAME;
    }
    this.adSpace.ID = await createAdSpaceAction(this.adSpace);
    await this.startUploadingMedia();
    if (this.isAdminUser) {
      this.$router.push(this.allAdSpacesRoute);
    } else if (this.isStoreOwner) {
      this.$router.push(this.userAdSpacesRoute);
    }
  }

  public async startUploadingMedia() {
    try {
      this.uploading = true;
      if (!this.selectedMediaFiles || this.selectedMediaFiles.length === 0) {
        throw new Error('You must select a file to upload.');
      }

      const uploadedMediaLinks = await Promise.all(
        this.selectedMediaFiles.map(async (file) => {
          const uploadTaskRef = await actions.uploadMediaForAdSpace(file, this.adSpace);
          const uploadTask = uploadTaskRef.put(file);
          return new Promise<Media>((resolve) => {
            uploadTask.on(
              storage.TaskEvent.STATE_CHANGED,
              null,
              (error: Error) => {
                this.uploading = false;
                /* tslint:disable-next-line */
                console.error(error);
              },
              async () => {
                const mediaLink =
                  uploadTask && (await uploadTask.snapshot.ref.getDownloadURL());

                const mediaFileData: Media = {
                  TYPE: file.type as MediaType,
                  PATH: mediaLink,
                };

                resolve(mediaFileData);
              },
            );
          });
        }));

      this.adSpace.MEDIA = uploadedMediaLinks;
      this.adSpace.MEDIA_FILE = uploadedMediaLinks[0].PATH;
      this.adSpace.MEDIA_TYPE = uploadedMediaLinks[0].TYPE;
      await updateAdSpaceAction(this.adSpace);
      this.selectedMediaFiles = [];
      this.uploading = false;
    } catch (error) {
      this.uploading = false;
    }
  }

  @Watch('adSpace.COUNTRY')
  public countryChanged() {
    this.adSpace.REGION = {} as Region;
  }

  @Watch('adSpace.CITY', { deep: true })
  public cityChanged(newData: City, oldData: City) {
    if (!this.adSpace.CITY) {
      return;
    }
    if (!oldData || oldData.GEONAME_ID !== this.adSpace.CITY.GEONAME_ID) {
      const region = getRegionByCityGeonameId(newData.GEONAME_ID);
      this.adSpace.CITY = newData;
      this.adSpace.REGION = region as Region;
    }
  }

  @Watch('$root.$i18n.locale')
  public onChangeLocale() {
    const [prevSelectedCountry, prevSelectedCity] = [
      this.adSpace.COUNTRY,
      this.adSpace.CITY,
    ];

    this.loadCountries();

    if (prevSelectedCountry) {
      this.adSpace.COUNTRY = this.countries.find(
        (country) => country.GEONAME_ID === prevSelectedCountry.GEONAME_ID,
      )!;
      this.loadCities(prevSelectedCountry);
    }

    if (prevSelectedCity) {
      this.adSpace.CITY = this.cities.find(
        (city) => city.GEONAME_ID === prevSelectedCity.GEONAME_ID,
      )!;
    }
  }
  public adSpaceScreenSaverCreated(adSpaceScreenSaver: AdSPaceScreenSaver) {
    this.adSpace.SCREEN_SAVERS = this.adSpace.SCREEN_SAVERS.concat(adSpaceScreenSaver);
  }
  public reorderMapper(adSpaceScreenSaver: AdSPaceScreenSaver, index: number) {
    adSpaceScreenSaver.ORDER = index + 1;
    return adSpaceScreenSaver;
      }
  public adSpaceScreenSaverDeleted(screenSaverMediaPath: string) {
    this.adSpace.SCREEN_SAVERS = this.adSpace.SCREEN_SAVERS
        .filter((adSpaceScreenSaver) => adSpaceScreenSaver.MEDIA_FILE.MEDIA_FILE !== screenSaverMediaPath)
        .map(this.reorderMapper);
  }
  public adSpaceScreenSaverReordered(adSpaceScreenSavers: AdSPaceScreenSaver[]) {
    this.adSpace.SCREEN_SAVERS = adSpaceScreenSavers.map(this.reorderMapper);
  }
}
