import { render, staticRenderFns } from "./ScreenSaverItem.vue?vue&type=template&id=23fee3fa&scoped=true&"
import script from "./ScreenSaverItem.vue?vue&type=script&lang=ts&"
export * from "./ScreenSaverItem.vue?vue&type=script&lang=ts&"
import style0 from "./ScreenSaverItem.vue?vue&type=style&index=0&id=23fee3fa&scoped=true&lang=sass&"


/* normalize component */
import normalizer from "!../../../../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23fee3fa",
  null
  
)

export default component.exports