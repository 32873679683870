import firebase from 'firebase';
import { FirebaseAppFirestore } from '@/app/shared/firebase/firebase-app';
import firebaseNames from '@/app/shared/utilities/firebase-names';
import { DocumentReference } from '@firebase/firestore-types';
import { AuthorizedUser, Campaign, CampaignListItem, CampaignTuple, Tuple } from '@/app/shared/utilities/static-types';
import { FILTER_QUERY_LIMIT, SYSTEM_STATUS } from '@/app/shared/utilities/object-factory';
import { segmentFiltersQueries } from '@/app/shared/utilities/helper-functions';
import { QueryDocumentSnapshot } from '@firebase/firestore-types';

/**
 * Load system campaigns for Admin approval.
 */
export const loadSystemCampaignRefsAction = async () => {
  const campaignsSnap = await FirebaseAppFirestore
    .collection(firebaseNames.CAMPAIGNS.VAL)
    .get();
  return campaignsSnap.docs.map((doc) => doc.data().campaignRef as DocumentReference);
};

/**
 * Load advertiser pending Store owner approval campaigns.
 */
export const loadPendingStoreOwnerApprovalCampaignsAction = async (): Promise<CampaignTuple[]> => {
  const campaignSnapshot = (await FirebaseAppFirestore
    .collectionGroup(firebaseNames.CAMPAIGNS.USER_CAMPAIGNS)
    .where('STATUS.VAL', '==', SYSTEM_STATUS.UNDER_PREPARATION.VAL)
    .get())
    .docs;
  const userCompanyNameMap = await getUserCompaniesNameMap(campaignSnapshot);

  return campaignSnapshot
    .map((doc) => [doc.data(), doc.ref] as CampaignTuple)
    .map(([campaignData, campaignRef]) => {
      campaignData.COMPANY_NAME = userCompanyNameMap[campaignData.ADVERTISER_UID];
      return [campaignData, campaignRef] as CampaignTuple;
    })
    .filter(([{ CREATED_BY_STORE_OWNER, APPROVED_BY_STORE_OWNER, APPROVED_BY_ADMIN }]) => !CREATED_BY_STORE_OWNER && !APPROVED_BY_STORE_OWNER && APPROVED_BY_ADMIN);
};

/**
 * Load advertiser pending admin approval campaigns.
 */
export const loadPendingAdminApprovalCampaignsAction = async (): Promise<CampaignTuple[]> => {
  const campaignSnapshot = (await FirebaseAppFirestore
    .collectionGroup(firebaseNames.CAMPAIGNS.USER_CAMPAIGNS)
    .where('STATUS.VAL', 'in', [
      SYSTEM_STATUS.PENDING_PAYMENT.VAL,
      SYSTEM_STATUS.UNDER_PREPARATION.VAL,
      SYSTEM_STATUS.RUNNING.VAL,
    ])
    .get())
    .docs;
  const userCompanyNameMap = await getUserCompaniesNameMap(campaignSnapshot);

  return campaignSnapshot
    .map((doc) => [doc.data(), doc.ref] as CampaignTuple)
    .map(([campaignData, campaignRef]) => {
      campaignData.COMPANY_NAME = userCompanyNameMap[campaignData.ADVERTISER_UID];
      return [campaignData, campaignRef] as CampaignTuple;
    })
    .filter(([{ CREATED_BY_STORE_OWNER, APPROVED_BY_ADMIN }]) => Boolean(!CREATED_BY_STORE_OWNER && !APPROVED_BY_ADMIN));
};

/**
 * Load approved campaigns data for Admin approval.
 */
export const loadAdvertisersApprovedCampaignsAction = async (): Promise<CampaignTuple[]> => {
  const campaignSnapshot = (await FirebaseAppFirestore
    .collectionGroup(firebaseNames.CAMPAIGNS.USER_CAMPAIGNS)
    .where('STATUS.VAL', '==', SYSTEM_STATUS.UNDER_PREPARATION.VAL)
    .get()).docs;

  const userCompanyNameMap = await getUserCompaniesNameMap(campaignSnapshot);


  return campaignSnapshot
    .map((doc) => [doc.data(), doc.ref] as CampaignTuple)
    .map(([ campaignData, campaignRef ]) => {
      campaignData.COMPANY_NAME = userCompanyNameMap[campaignData.ADVERTISER_UID];
      return [ campaignData, campaignRef ]as Tuple<
        typeof campaignData & { COMPANY_NAME: string },
        typeof campaignRef
        >;
    })
    .filter(([{ CREATED_BY_STORE_OWNER, APPROVED_BY_ADMIN }]) => Boolean(!CREATED_BY_STORE_OWNER && APPROVED_BY_ADMIN));
};

/**
 * Load advertiser's other campaigns.
 * The campaigns other than ones need approval or approved.
 */
export const loadAdvertisersAllCampaignsAction = async (): Promise<CampaignTuple[]> => {
  const includedCampaignsTypes = [
    SYSTEM_STATUS.PENDING_PAYMENT.VAL,
    SYSTEM_STATUS.UNDER_PREPARATION.VAL,
    SYSTEM_STATUS.REQUIRES_ACTION.VAL,
    SYSTEM_STATUS.UPCOMING.VAL,
    SYSTEM_STATUS.RUNNING.VAL,
    SYSTEM_STATUS.FINISHED.VAL,
  ];
  const campaignSnapshot = (await FirebaseAppFirestore
    .collectionGroup(firebaseNames.CAMPAIGNS.USER_CAMPAIGNS)
    .where('STATUS.VAL', 'in', includedCampaignsTypes)
    .get())
    .docs;

  const userCompanyNameMap = await getUserCompaniesNameMap(campaignSnapshot);

  return campaignSnapshot
    .map((doc) => [doc.data(), doc.ref] as CampaignTuple)
    .map(([campaignData, campaignRef]) => {
      campaignData.COMPANY_NAME = userCompanyNameMap[campaignData.ADVERTISER_UID];
      return [campaignData, campaignRef] as CampaignTuple;
    })
    .filter(([{ CREATED_BY_STORE_OWNER }]) => !CREATED_BY_STORE_OWNER);
};

/**
 * Approve campaign as admin.
 * @param campaign Campaign
 */
export const approveCampaignAction = async (campaign: Campaign): Promise<void> => {
  const { ADVERTISER_UID: userId, ID: campaignId, STATUS: campaignStatus, SKIP_INVOICE = false, AD_SPACES_BILLING } = campaign;
  const campaignRef = FirebaseAppFirestore
    .collection(firebaseNames.CAMPAIGNS.VAL)
    .doc(userId)
    .collection(firebaseNames.CAMPAIGNS.USER_CAMPAIGNS)
    .doc(campaignId);
  const STATUS = campaignStatus.VAL === SYSTEM_STATUS.PENDING_PAYMENT.VAL
    ? SYSTEM_STATUS.UNDER_PREPARATION
    : campaignStatus;
  const PAYMENT_STATUS = campaignStatus.VAL === SYSTEM_STATUS.PENDING_PAYMENT.VAL ? 'APPROVED' : 'FAILED';
  return campaignRef.update({
    SKIP_INVOICE,
    APPROVED_BY_ADMIN: true,
    APPROVED_BY_ADMIN_AT: firebase.firestore.Timestamp.now(),
    STATUS,
    PAYMENT_STATUS,
    DESIGNER_ACCESS_CODE: '',
    // AD_SPACES_BILLING,
  });
};

/**
 * Reject campaign as admin.
 * @param campaign Campaign
 */
export const rejectCampaignAction = async (campaign: Campaign): Promise<void> => {
  const { ADVERTISER_UID: userId, ID: campaignId, COMMENTS, SKIP_INVOICE = false } = campaign;
  const campaignRef = FirebaseAppFirestore
    .collection(firebaseNames.CAMPAIGNS.VAL)
    .doc(userId)
    .collection(firebaseNames.CAMPAIGNS.USER_CAMPAIGNS)
    .doc(campaignId);
  return campaignRef.update({
    SKIP_INVOICE,
    APPROVED_BY_ADMIN: false,
    STATUS: SYSTEM_STATUS.REQUIRES_ACTION,
    COMMENTS,
    REJECTED_AT: firebase.firestore.Timestamp.now(),
  });
};

/**
 * Reject campaign's change media request as an admin.
 * @param campaign Campaign
 */
export const rejectCampaignChangeMediaRequestAction = async (campaign: Campaign): Promise<void> => {
  const { ADVERTISER_UID: userId, ID: campaignId, COMMENTS } = campaign;
  const campaignRef = FirebaseAppFirestore
    .collection(firebaseNames.CAMPAIGNS.VAL)
    .doc(userId)
    .collection(firebaseNames.CAMPAIGNS.USER_CAMPAIGNS)
    .doc(campaignId);
  return campaignRef.update({
    REJECTED_AT: firebase.firestore.Timestamp.now(),
    MEDIA_CHANGE_REQUEST_STATUS: SYSTEM_STATUS.REJECTED,
    COMMENTS,
  });
};
// This function is to get the map of user company name.
// key is the userId and value is the company name
async function getUserCompaniesNameMap(campaignSnapshot: QueryDocumentSnapshot[]): Promise<Record<AuthorizedUser['UID'], AuthorizedUser['COMPANY_NAME']>> {
  const userIds = campaignSnapshot
    .map((doc) => doc.data().ADVERTISER_UID);
  const usersList = await segmentFiltersQueries(FILTER_QUERY_LIMIT, userIds, 'UID', firebaseNames.USERS_INFO);
  return usersList.reduce((users: Record<CampaignListItem['ADVERTISER_UID'], CampaignListItem['COMPANY_NAME']>,
                           user: AuthorizedUser) =>
    ({ ...users, [user.UID]: user.COMPANY_NAME }), {} as Record<CampaignListItem['ADVERTISER_UID'], CampaignListItem['COMPANY_NAME']>);
}
