
























import { Component, Prop, Vue } from 'vue-property-decorator';
import {
  AdSpace,
  CampaignMedia,
  DefaultScreenSaver,
} from '@/app/shared/utilities/static-types';
import {
  deleteScreenSaverAndReorder,
  loadAdSpacesAction,
} from '@adminSchedules/shared/actions';
import BlockingProgress from '@/app/shared/components/BlockingProgress.vue';
import {
  deleteScreenSaversRelatedToAdSpacesAction,
  updateAdSpaceAction,
} from '@adSpaces/shared/actions';
import {
  convertAdSpaceListToMap,
  convertScreenSaverMediaListToMap,
} from '@/app/shared/utilities/helper-functions';
import { FirebaseAppFirestore } from '@/app/shared/firebase/firebase-app';

@Component({
  name: 'DeleteScreenSaver',
  components: {
    BlockingProgress,
  },
})
export default class DeleteScreenSaver extends Vue {
  @Prop() public screenSaver!: DefaultScreenSaver;
  @Prop() public showDeleteScreenSaver!: boolean;
  public closeEvent = 'update:show-delete-screen-saver';

  get showDeleteScreenSaverValue() {
    return this.showDeleteScreenSaver;
  }

  set showDeleteScreenSaverValue(showDeleteScreenSaver: boolean) {
    this.$emit(this.closeEvent, showDeleteScreenSaver);
  }

  public deleteScreenSaverOnly() {
    deleteScreenSaverAndReorder(this.screenSaver);
    this.$emit(this.closeEvent, false);
    this.$emit('screen-saver-deleted');
  }

  public async deleteScreenSaverRelatedToAdSpaces() {
    (this.$refs.progress as BlockingProgress).show();
    try {
      const adSpaces = await loadAdSpacesAction();

      // const promiseArray: Promise<void>[] = [];
      const batch = FirebaseAppFirestore.batch();
      const screenSaverMap = convertScreenSaverMediaListToMap(
        this.screenSaver.MEDIA_LIST,
      );

      const adSpaceMap = convertAdSpaceListToMap(adSpaces);
      Object.entries(screenSaverMap).forEach(
        ([screenResolution, screenSaverMedia]) => {
          const adSpacesMatchesResolution = adSpaceMap[screenResolution];
          adSpacesMatchesResolution.forEach((adSpace) => {
            deleteScreenSaversRelatedToAdSpacesAction(
              batch,
              adSpace,
              this.screenSaver,
              screenSaverMedia,
            );
          });
        },
      );
      await batch.commit();
    } finally {
      (this.$refs.progress as BlockingProgress).hide();
    }
  }
}
