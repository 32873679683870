export default {
  REDIRECT: { name: 'REDIRECT' },
  HOME: { name: 'HOME' },
  LOGIN: { name: 'LOGIN' },
  REGISTER: { name: 'REGISTER'},
  FORGET_PASSWORD: { name: 'FORGET_PASSWORD' },
  RESET_PASSWORD: { name: 'RESET_PASSWORD' },
  ACCESS_DENIED: { name: 'ACCESS_DENIED' },
  LOGOUT: { name: 'LOGOUT' },
  DASHBOARD: { name: 'DASHBOARD' },
  USER_SETTINGS: { name: 'USER_SETTINGS' },
  COMPANY_MANAGEMENT: { name: 'COMPANY_MANAGEMENT' },
  FILE_MANAGEMENT: { name: 'FILE_MANAGEMENT' },
  MY_CAMPAIGNS: { name: 'MY_CAMPAIGNS' },
  MANAGE_STORE_CAMPAIGNS: { name: 'MANAGE_STORE_CAMPAIGNS' },
  CAMPAIGN_NEW: { name: 'CAMPAIGN_NEW' },
  CAMPAIGN_EDIT: { name: 'CAMPAIGN_EDIT' },
  CAMPAIGN_REVIEW: { name: 'CAMPAIGN_REVIEW' },
  CHECKOUT_PAGE: { name: 'CHECKOUT_PAGE' },
  PAYMENT_STATUS: { name: 'PAYMENT_STATUS' },
  MY_DEVICES: { name: 'MY_DEVICES' },
  MY_DEVICE_NEW: { name: 'MY_DEVICE_NEW' },
  MY_DEVICE_VIEW: { name: 'MY_DEVICE_VIEW' },
  MY_DEVICE_CONNECT_LIST: { name: 'MY_DEVICE_CONNECT_LIST' },
  MY_DEVICE_CONNECT: { name: 'MY_DEVICE_CONNECT' },
  STORE_DEVICE_VIEW: { name: 'STORE_DEVICE_VIEW' },
  STORE_DEVICES: { name: 'STORE_DEVICES' },
  STORE_CAMPAIGN_NEW: { name: 'STORE_CAMPAIGN_NEW' },
  STORE_CAMPAIGN_EDIT: { name: 'STORE_CAMPAIGN_EDIT' },
  STORE_CAMPAIGNS: '/store-campaigns',
  STORE_CAMPAIGN_CHECKOUT_PAGE: { name: 'STORE_CAMPAIGN_CHECKOUT_PAGE' },
  AD_SPACES: { name: 'AD_SPACES' },
  MY_AD_SPACES: { name: 'MY_AD_SPACES' },
  AD_SPACE_NEW: { name: 'AD_SPACE_NEW' },
  AD_SPACE_EDIT: { name: 'AD_SPACE_EDIT' },
  CHANGE_PASSWORD: { name: 'CHANGE_PASSWORD' },
};

export const AdminRoutes = {
  ADMIN_DASHBOARD: { name: 'ADMIN_DASHBOARD' },
  APPROVED_BRANCHES: { name: 'APPROVED_BRANCHES' },
  PENDING_BRANCHES: { name: 'PENDING_BRANCHES' },
  CREATE_STORE_OWNER: { name: 'CREATE_STORE_OWNER' },
  EDIT_STORE_OWNER: { name: 'EDIT_STORE_OWNER' },
  EDIT_REGULATOR: { name: 'EDIT_REGULATOR' },
  CREATE_REGULATOR: { name: 'CREATE_REGULATOR' },
  REGULATORS: { name: 'REGULATORS' },
  STORE_OWNERS: { name: 'STORE_OWNERS' },
  CREATE_STORE_AD_SPACE: { name: 'CREATE_STORE_AD_SPACE' },
  EDIT_STORE_AD_SPACE: { name: 'EDIT_STORE_AD_SPACE' },
  ALL_AD_SPACES: { name: 'ALL_AD_SPACES' },
  APPROVED_SCREENS: { name: 'APPROVED_SCREENS' },
  PENDING_SCREENS: { name: 'PENDING_SCREENS' },
  REGISTERED_PLAYERS: { name: 'REGISTERED_PLAYERS' },
  PENDING_SCREENS_VIEW: { name: 'PENDING_SCREENS_VIEW' },
  CREATE_SCREEN_OWNER: { name: 'CREATE_SCREEN_OWNER' },
  CREATE_SCREEN_CLUSTER: { name: 'CREATE_SCREEN_CLUSTER' },
  ADVERTISER_PENDING_ADMIN_CAMPAIGNS: { name: 'ADVERTISER_PENDING_ADMIN_CAMPAIGNS' },
  ADVERTISER_PENDING_STORE_OWNER_CAMPAIGNS: { name: 'ADVERTISER_PENDING_STORE_OWNER_CAMPAIGNS' },
  ADVERTISER_ALL_CAMPAIGNS: { name: 'ADVERTISER_ALL_CAMPAIGNS' },
  ADVERTISER_USER_LIST: { name: 'ADVERTISER_USER_LIST' },
  STORE_APPROVED_CAMPAIGNS: { name: 'STORE_APPROVED_CAMPAIGNS' },
  STORE_PENDING_CAMPAIGNS: { name: 'STORE_PENDING_CAMPAIGNS' },
  STORE_CAMPAIGN_REVIEW: { name: 'SRTORE_CAMPAIGN_REVIEW' },
  STORE_OTHER_CAMPAIGNS: { name: 'STORE_OTHER_CAMPAIGNS' },
  ADVERTISER_CAMPAIGN_REVIEW: { name: 'ADVERTISER_CAMPAIGN_REVIEW' },
  APPROVED_PROFILES: { name: 'APPROVED_PROFILES' },
  PENDING_PROFILES: { name: 'PENDING_PROFILES' },
  PENDING_PROFILES_VIEW: { name: 'PENDING_PROFILES_VIEW' },
  MANAGE_USERS_ROLES: { name: 'MANAGE_USERS_ROLES' },
  SYSTEM_FEATURES: { name: 'SYSTEM_FEATURES' },
  ADSPCACES_PLAYLISTS: { name: 'ADSPCACES_PLAYLISTS' },
  SCREEN_SAVER: { name: 'SCREEN_SAVER' },
  MANAGE_PRICING: { name: 'MANAGE_PRICING' },
  MANAGE_USERS_EDIT: { name: 'MANAGE_USERS_EDIT' },
  ROLES_SETTINGS: { name: 'ROLES_SETTINGS' },
  SCHEDULE_PLAYERS: { name: 'SCHEDULE_PLAYERS' },
  PROMOTIONS: { name: 'PROMOTIONS' },
  CREATE_PROMOTION: { name: 'CREATE_PROMOTION' },
  EDIT_PROMOTION: { name: 'EDIT_PROMOTION' },
  PROMOTIONS_LIST: { name: 'PROMOTIONS_LIST' },
  REGISTER_USER: { name: 'REGISTER_USER' },
};
