import { TranslateResult } from 'vue-i18n';
import {
  DocumentReference,
  FieldValue,
  Timestamp,
} from '@firebase/firestore-types';
import firebase from 'firebase';

/** @see [TS Omit Property Helper]{@link https://devblogs.microsoft.com/typescript/announcing-typescript-3-5-rc/#the-omit-helper-type} */
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** @see [TS Optional Property Utility]{@link https://github.com/Microsoft/TypeScript/issues/25760#issuecomment-614417742} */
export type Optional<T, K extends keyof T> = Omit<T, K> & Partial<T>;
export type WithRequired<T, K extends keyof T> = T & Required<Pick<T, K>>;
/** @see [TS valueof Array Utility]{@link https://github.com/microsoft/TypeScript/issues/20965#issuecomment-354858633} */
export type valueof<T extends any[]> = T[number];

export type Tuple<T, K> = [T, K];

export type Nullable<T> = T | null | undefined;

export type Callback<A, B> = (arg: A) => B;

export type Base64 = string;
export type MimeString =
  | 'image/png'
  | 'image/jpeg'
  | 'application/pdf'
  | 'text/plain';

export interface AuthorizedUser {
  UID: string;
  EMAIL: string;
  PHONE_NUMBER: string;
  COMPANY_NAME: string;
  NAME: string;
  POSITION: JobPosition;
  ROLES?: Role[];
  HAS_COMPANY_PROFILE: boolean;
  HAS_PUBLISHED_CAMPAIGNS: boolean;
  ZOHO_CONTACT_ID?: string;
}

export interface UserProfileItem {
  title: string;
  navigateToPath?: {};
  clickHandler?: (e?: any) => void;
}

export interface UserInfo {
  COMPANY_NAME: string;
  EMAIL: string;
  PHONE_NUMBER: string;
  POSITION: JobPosition;
  ROLES: Role[];
  UID: string;
}

export interface CompanyProfile {
  USER_ID: string;
  COMPANY_NAME: string;
  COMPANY_REGISTRY_NAME: string;
  COMPANY_REGISTRATION_NUMBER: string;
  COMPANY_REGISTRATION_CERTIFICATE: CompanyRegCertificate;
  HEADQUARTER_COUNTRY: Country;
  COMPANY_ADDRESS: string;
  COMPANY_PRODUCTS: string[];
  STATUS: SystemStatus;
  COMPANY_VAT?: string;
  COMPANY_SIZE?: string;
  COMMENTS?: string;
  BILLING_CONTACTS?: BillingContact[];
}

export interface CompanyRegCertificate {
  CONTENT_TYPE: string;
  STATIC_LINK: string;
}

export const PAYMENT_STATUSES = {
  APPROVED: 'APPROVED' as 'APPROVED',
  FAILED: 'FAILED' as 'FAILED',
};

export type PaymentStatus = keyof typeof PAYMENT_STATUSES;

export interface PaymentMetaData {
  checkoutId: string;
  paymentBill: CampaignPriceInfo;
  returnUrl: string;
}

export interface StoreCampaign {
  campaignRef: string;
}

export enum FREQUENCY {
  '1/2X' = '1/2x',
  '1X'   = '1x',
  '2X'   = '2x',
}

export interface CampaignFrequency {
  NAME: FREQUENCY;
  MINUTES: number;
  COST_FACTOR: number;
  LABEL: string;
}

export interface Campaign {
  ID: string;
  NAME: string;
  SELECTED_CLUSTERS_SCHEDULES: ClustersSchedulesRecord;
  SCREEN_CLUSTERS: ScreenCluster[];
  SCREEN_CLUSTERS_IDS: string[];
  MEDIA_FILE: string;
  MEDIA_TYPE: 'image/jpeg' | 'image/gif' | 'video/mp4';
  MEDIA_PLAYBACK_DURATION?: number;
  MEDIA_SKIPPED: boolean;
  STATUS: SystemStatus;
  ADVERTISER_UID: string;
  COMMENTS?: string;
  PAYMENT_BILL?: CampaignPriceInfo;
  AD_SPACES_BILLING: CampaignAdSpacesBilling;
  RESERVED_UNTIL?: Timestamp;
  RESERVED_TIME_SLOTS?: CampaignTimeSlot[];
  PAYMENT_REFERENCE?: string;
  PAYMENT_STATUS?: PaymentStatus;
  SUBSCRIPTION?: boolean;
  SKIP_INVOICE?: boolean;
  INVOICE_NUMBER?: string;
  ESTIMATE_NUMBER?: string;
  CREATED_BY_STORE_OWNER?: boolean;
  CREATED_BY_ADMIN?: boolean;
  APPROVED_BY_ADMIN?: boolean;
  APPROVED_BY_ADMIN_AT?: Timestamp;
  APPROVED_BY_STORE_OWNER?: boolean;
  ACTION_STORE_OWNER_ID?: string;
  START_DATE: Timestamp | string;
  END_DATE: Timestamp | string;
  REJECTED_AT?: Timestamp | string;
  DURATION_IN_WEEKS?: number;
  MEDIA_LIST?: CampaignMedia[];
  FREQUENCY?: CampaignFrequency;
  MEDIA_UPLOADED_AT?: Timestamp;
  MEDIA_CHANGE_ACTION_STORE_OWNER_ID?: string;
  CHANGE_REQUEST_MEDIA_STATUS?: SystemStatus;
  MEDIA_CHANGE_REQUEST_STATUS?: SystemStatus;
  DESIGNER_EMAIL: string;
  DESIGNER_ACCESS_CODE?: string;
  [index: string]: any;
}

export interface CampaignMedia {
  MEDIA_FILE?: string;
  MEDIA_TYPE?: MediaType;
  MEDIA_PLAYBACK_DURATION?: number;
  MEDIA_UPLOADED_AT?: Timestamp;
  MEDIA_SKIPPED?: boolean;
  WIDTH?: number;
  HEIGHT?: number;
  UNIT?: 'px';
  AD_SPACE_ID?: string;
  REJECTION_REASON?: string;
  MEDIA_ID?: string;
  NAME?: string;
  MEDIA_CHANGE_REQUEST?: CampaignMedia|null;
}

export interface PendingPaymentCampaign
  extends WithRequired<
    Campaign,
    | 'PAYMENT_BILL'
    | 'RESERVED_TIME_SLOTS'
    | 'RESERVED_UNTIL'
    | 'ESTIMATE_NUMBER'
  > {
  SCREEN_CLUSTERS: ScreenClusterWithZohoItem[];
}
export interface PublishedCampaign
  extends WithRequired<
    Campaign,
    | 'PAYMENT_BILL'
    | 'RESERVED_TIME_SLOTS'
    | 'PAYMENT_STATUS'
    | 'PAYMENT_REFERENCE'
    | 'INVOICE_NUMBER'
  > {
  SCREEN_CLUSTERS: ScreenClusterWithZohoItem[];
}
export type PendingApprovalCampaign =
  | PendingPaymentCampaign
  | PublishedCampaign;
export type RejectedCampaign = WithRequired<
  PendingApprovalCampaign,
  'COMMENTS'
>;
export type ApprovedCampaign = WithRequired<
  PublishedCampaign,
  'APPROVED_BY_ADMIN' | 'APPROVED_BY_STORE_OWNER' | 'APPROVED_BY_ADMIN_AT'
>;

export interface CampaignTimeSlot {
  TIME_SLOT_REF: DocumentReference;
  SCHEDULE_REF: DocumentReference;
}

export type CampaignUpdate = Pick<
  Campaign,
  | 'ID'
  | 'NAME'
  | 'SCREEN_CLUSTERS'
  | 'SCREEN_CLUSTERS_IDS'
  | 'MEDIA_FILE'
  | 'MEDIA_TYPE'
  | 'STATUS'
  | 'MEDIA_SKIPPED'
  | 'MEDIA_PLAYBACK_DURATION'
>;

export type CampaignFormData = Pick<
  Campaign,
  'NAME' | 'SELECTED_CLUSTERS_SCHEDULES' | 'START_DATE' | 'DURATION_IN_WEEKS'
> & { SCREEN_CLUSTERS: ScreenCluster[] };

export interface CampaignMediaFile {
  FILE_NAME: string;
  FILE_TYPE: string;
  PLAYBACK_DURATION?: number;
  STATIC_LINK: string;
}

export interface Schedule {
  ID: string;
  AD_SPACE_ID: string;
  START_DATE: string;
  END_DATE: string;
  START_MONTH: string;
  END_MONTH: string;
  WEEK: number;
  DAYS: string;
  START_YEAR: string;
  END_YEAR: string;
  SCHEDULE_NAME: string;
  FREE_SLOTS_COUNT?: number;
  TIME_SLOT_ID?: string;
  AVAILABLE: boolean;
  TOTAL_BILL_PRICE: number;
  BASE_PRICE: number;
  PRICE_INCREASE_PERCENTAGE: number;
  PRICE_INCREASE_REASON: string;
  SELECTED?: boolean;
}

export interface ScheduleRequest {
  startUpcomingWeek: number;
  endUpcomingWeek: number;
}

export interface CampaignRequest {
  ID: string;
  ADVERTISER_UID: string;
}

export interface CampaignPaymentRequest {
  campaignInfo: CampaignRequest;
  promoCode?: string;
}

export interface CampaignCheckoutRequest {
  campaignRequest: CampaignRequest;
  promoCode?: string;
  // cardInfo: CardInfo;
  // billingInfo: BillingInfo;
  language?: 'en' | 'ar';
}

export interface TimeSlot {
  ID: string;
  PLAY_ORDER: number;
  DURATION_SECS: number;
  EXTENSION?: 'jpeg' | 'gif' | 'mp4';
  MEDIA_TYPE?: 'VIDEO' | 'IMAGE';
  RESERVED: boolean;
  CAMPAIGN?: DocumentReference;
}

export const DISCOUNTS = {
  twenty_percent_off_one_month: 'twenty_percent_off_one_month' as 'twenty_percent_off_one_month',
  hundred_riyals_for_first_campaign_week: 'hundred_riyals_for_first_campaign_week' as 'twenty_percent_off_one_month',
};

type DiscountReason = MultiLanguage;
export interface Discount {
  discountReason: DiscountReason;
  discountValue: number;
}

export interface PaymentBill {
  SUBTOTAL: number;
  DISCOUNTS_LIST: Discount[];
  ERROR_MESSAGE?: string;
  TOTAL_DISCOUNT: number;
  VAT: number;
  TOTAL_PRICE: number;
}

export type CampaignClusterPrice = number;
export type CampaignClustersPricesRecord = Record<
  ClusterId,
  CampaignClusterPrice
>;
export type CampaignClustersDiscountsRecord = Record<
  ClusterId,
  Nullable<Discount>
>;

export interface CampaignPriceInfo extends PaymentBill {
  CLUSTERS_PRICES_RECORD: CampaignClustersPricesRecord;
  CLUSTERS_DISCOUNTS_RECORD: CampaignClustersDiscountsRecord;
}

export const PAYMENT_METHODS = {
  VISA: 'VISA' as 'VISA',
  MASTER: 'MASTER' as 'MASTER',
  MADA: 'MADA' as 'MADA',
};

export type PaymentMethod = keyof typeof PAYMENT_METHODS;

export interface CardInfo {
  cardholderName: string;
  paymentMethod: PaymentMethod;
  cardNumber: string;
  expiryMonth: string;
  expiryYear: string;
  ccv: string;
}

export interface BillingAddress {
  firstName: string;
  lastName: string;
  addrLine1: string;
  addrLine2?: string;
  city: City;
  state: City;
  postalCode: string;
  country: Country;
  email: string;
  phoneNumber: string;
}

// export interface BillingInfo {
// billingAddress: BillingAddress;
// currency: 'SAR';
// quantity: string;
// unitPrice: string;
// totalPrice: string;
// product?: string;
// }

export interface SystemStatus {
  VAL: string;
  COLOR: string;
}

type AdSpaceId = string;
export type CampaignAdSpacesBilling = Record<AdSpaceId, AdSpaceBilling>;

export interface AdSpaceBilling {
  DISPLAY_NAME: string;
  VAL: 'auto' | 'manual' | 'none';
  OWNER_SHARE?: number;
}

export interface StoreType {
  DISPLAY_NAME: string;
  VAL: string;
}

export interface StoreContractType {
  DISPLAY_NAME: string;
  VAL: 'revenue_sharing' | 'fixed_amount' | 'rent';
  SHARE: number;
}

export interface JobPosition {
  DISPLAY_NAME: string;
  VAL: string;
}

export interface Role {
  DISPLAY_NAME: string;
  VAL: string;
}

export interface SystemFeature {
  ID?: string;
  ROUTE_PATH: string;
  ROUTE_NAME: { name: string };
  TITLE: string;
  ICON: string;
  HAS_CHILDREN: boolean;
  ROLES?: Role[];
}

export interface ScreenCluster {
  ID: string;
  PRICE: number;
  TIME_SLOTS: number;
  DISPLAY_NAME: MultiLanguage;
  TEXT?: string;
  DESCRIPTION: MultiLanguage;
  ZOHO_ITEM_ID?: string;
  ZOHO_ITEM_NAME: string;
}

export type ScreenClusterWithZohoItem = WithRequired<
  ScreenCluster,
  'ZOHO_ITEM_ID'
>;

export interface MultiLanguage {
  ar: string;
  en: string;
  [key: string]: string;
}

export type ClusterId = string;
export type AdSpaceSchedules = Schedule[];
export type ClustersSchedulesRecord = Record<ClusterId, AdSpaceSchedules>;
export type ClustersSchedulesTuple = Tuple<ClusterId, AdSpaceSchedules>;

export interface City {
  DISPLAY_NAME: MultiLanguage;
  GEONAME_ID: string;
  POPULATION?: number;
  TEXT?: string;
}
export interface Region {
  DISPLAY_NAME: MultiLanguage;
  GEONAME_ID: string;
  POPULATION?: number;
}
export interface Country {
  GEONAME_ID: string;
  TEXT?: string;
  COUNTRY_CODE: string;
  DISPLAY_NAME: string;
}

export interface HostInfo {
  NAME: string;
  COMPANY_NAME: string;
  EMAIL: string;
  PHONE_NUMBER: string;
}

export interface StoreAccount {
  UID?: string;
  STORE_NAME: string;
  STORE_TYPE: StoreType;
  BRAND_NAME: string;
  CONTRACT_TYPE: StoreContractType;
  HEADQUARTER_COUNTRY: Country;
  HEADQUARTER_CITY: City;
  EMAIL: string;
  CONTACT_PERSON?: ContactPerson;
  BRANCHES_COUNT?: number;
}

export interface RegulatorAccount {
  UID?: string;
  EMAIL: string;
  NAME: string;
  CONTACT_PERSON?: ContactPerson;
  }

export interface ContactPerson {
  NAME?: string;
  PHONE?: string;
  EMAIL?: string;
}

export interface Branch {
  ID: string;
  DISPLAY_NAME: string;
}

export interface MyDevice {
  ID?: string;
  DISPLAY_NAME: string;
  COUNTRY: Country;
  CITY: City;
  STORE_ID: string;
  STORE_NAME: string;
  AD_SPACE_ID: string;
  AD_SPACE_NAME: string;
  STORE_AREA?: string;
  STATUS: SystemStatus;
  COMMENTS?: string;
  TAGS?: string[];
  SCREEN_CODE?: string;
  PHOTO_URL?: string;
  REGISTERED_PLAYER_ID?: string;
}

export interface MyDeviceInfo {
  COUNTRY: Country;
  CITY: City;
  STORE_ID: string;
  STORE_NAME: string;
  SCREEN_CODE: string;
  DEVICE_NAME: string;
  DEVICE_REF: DocumentReference;
}

export interface RegisteredPlayer {
  DEVICE_REF: DocumentReference;
  SCHEDULE_REF?: DocumentReference;
  CONNECTION: 'PENDING' | 'READY';
  STATUS?: PlayerStatus;
  LAST_CHECKED?: FieldValue;
}

export const PLAYER_STATUS = {
  ENTER_FULLSCREEN: 'ENTER_FULLSCREEN' as 'ENTER_FULLSCREEN',
  EXIT_FULLSCREEN: 'EXIT_FULLSCREEN' as 'EXIT_FULLSCREEN',
  PLAYER_CLOSED: 'PLAYER_CLOSED' as 'PLAYER_CLOSED',
  PLAYER_HIDDEN: 'PLAYER_HIDDEN' as 'PLAYER_HIDDEN',
  PLAYER_BLURRED: 'PLAYER_BLURRED' as 'PLAYER_BLURRED',
  PLAYER_FROZEN: 'PLAYER_FROZEN' as 'PLAYER_FROZEN',
  PLAYER_ONLINE: 'PLAYER_ONLINE' as 'PLAYER_ONLINE',
  PLAYER_OFFLINE: 'PLAYER_OFFLINE' as 'PLAYER_OFFLINE',
};

export type PlayerStatus = keyof typeof PLAYER_STATUS;

export interface UploadToken {
  token: string;
  metadata?: UserStorageStatus;
}

export interface UserStorageStatus {
  STORAGE_LIMIT_IN_BYTES: number;
  STORAGE_USED_IN_BYTES: number;
  STORAGE_LEFT_IN_BYTES: number;
}

export interface SizeFormat {
  bytes: number;
  format: string;
}

export interface InvitationCode {
  CODE: string;
  REDEEM_COUNT: number;
}

export interface CardSchema {
  type: string;
  pattern: RegExp;
  format: RegExp;
  length: number[];
  cvcLength: number[];
  luhn: boolean;
}

export interface MonthYear {
  month: number;
  year: number;
}

export interface DeferredPromise {
  resolve?: (value?: any | PromiseLike<any>) => void;
  reject?: (reason?: any) => void;
  readonly promise: Promise<any>;
}

export interface Locales {
  en: 'English';
  ar: 'العربية';
  [key: string]: string;
}

export interface AdSpaceType {
  DISPLAY_NAME: string;
  VAL: string;
}

export enum AdSpaceScreensPosition {
  INDOOR = 'indoor',
  OUTDOOR = 'outdoor',
}

export enum AdSpacePriceControl {
  BY_SHASHA      = 'shasha',
  BY_SPACE_OWNER = 'space_owner',
}

export interface AdSpace {
  ID: string;
  REGULATOR_ID: string;
  SCREEN_CLUSTER_REF?: DocumentReference;
  SPACE_OWNER_ID: string;
  NAVORI_GROUP_ID: number;
  CONTRACT_TYPE: StoreContractType;
  NAME: string;
  BRANCH: string;
  BRAND_NAME: string;
  DESCRIPTION: string;
  TYPE: AdSpaceType;
  SCREENS_POSITION: AdSpaceScreensPosition;
  PRICE_CONTROL: AdSpacePriceControl;
  CITY: City;
  COUNTRY: Country;
  BASE_PRICE: number;
  PRICE_INCREASE_PERCENTAGE?: number;
  PRICE_INCREASE_REASON?: string;
  SCREENS_COUNT: number;
  SCREENS_SIZES: string;
  SCREENS_RESOLUTION: ScreenResolution;
  MEDIA?: Media[];
  MEDIA_FILE?: string;
  MEDIA_TYPE?: MediaType;
  ADDRESS: string;
  VISITORS_PER_WEEK: number;
  IS_ONLINE?: boolean;
  COORDINATES: {
    lng: number;
    lat: number;
  };
  SCREEN_SAVERS: AdSPaceScreenSaver[];
  REGION: Region;

}

export interface ClusterSchedulesRequest {
  clusterId: string;
  scheduleRequest?: ScheduleRequest;
}

export interface FileMgmntMenuAction {
  title: string;
  icon?: string;
  action: any;
}

export interface Promotion {
  ID?: string;
  CODE: string;
  NAME: string;
  REDEEM_MESSAGE: MultiLanguage;
  PLATFORM_MESSAGE: MultiLanguage;
  PLATFORM_VISIBLE: boolean;
  START_DATE: Timestamp | string;
  END_DATE: Timestamp | string;
  TYPE: PromotionType;
  APPLICABLE_SCREEN_CLUSTER_IDS: Array<ScreenCluster['ID']>;
  MINIMUM_NUMBER_OF_WEEKS_IN_CAMPAIGN: number | null;
  FIRST_PUBLISHED_CAMPAIGN_ONLY: boolean;
  VALUE_CAP?: number | null;
  WEEKS_PERIOD_CAP?: number | null;
  DEFAULT?: boolean;
}

export interface PromotionTableRow {
  ID?: string;
  NAME: string;
  DEFAULT?: boolean;
  PLATFORM_VISIBLE: boolean;
  STATUS: string;
  VALUE: string;
  TYPE: string;
  START_DATE: string;
  END_DATE: string;
  APPLICABLE_LOCATIONS_NAMES: string;
  FIRST_PUBLISHED_CAMPAIGN_ONLY?: boolean;
}

type CampaignRef = DocumentReference;
export type CampaignTuple = Tuple<Campaign, CampaignRef>;

export interface CampaignListItem extends Campaign {
  REF: DocumentReference;
  SCREEN_CLUSTERS_NAMES: string;
  COMPANY_NAME?: string;
  PRICE?: string;
  [key: string]: any;
}
export type CampaignTableRow = CampaignListItem;

export interface CampaignDiscounts {
  errorMessage?: string;
  totalDiscount: number;
  discountList: Discount[];
}

export interface PromotionType {
  RATE: PromotionRate;
  VALUE: number;
}

export const PROMOTION_RATES = {
  DISCOUNT: 'DISCOUNT' as 'DISCOUNT',
  FIXED_PRICE: 'FIXED_PRICE' as 'FIXED_PRICE',
};

type PromotionRate = keyof typeof PROMOTION_RATES;

export const PROMOTION_STATUSES = {
  ACTIVE: 'ACTIVE' as 'ACTIVE',
  EXPIRED: 'EXPIRED' as 'EXPIRED',
  UPCOMING: 'UPCOMING' as 'UPCOMING',
};

export const MEDIA_TYPES = {
  'image/jpeg': 'image/jpeg' as 'image/jpeg',
  'image/gif': 'image/gif' as 'image/gif',
  'video/mp4': 'video/mp4' as 'video/mp4',
};

export type MediaType = keyof typeof MEDIA_TYPES;

export interface Media {
  PATH: string;
  TYPE: MediaType;
}

export type PromotionStatus = keyof typeof PROMOTION_STATUSES;

export const USER_TYPE = {
  advertiser: 'advertiser' as 'advertiser',
  storeOwner: 'storeOwner' as 'storeOwner',
  admin: 'admin' as 'admin',
};

export type UserType = keyof typeof USER_TYPE;

export interface KeyValue {
  [key: string]: any;
}
export interface CampaignRoutes {
  admin: KeyValue;
  storeOwner: KeyValue;
  advertiser: KeyValue;
}
export interface VueClipboardsEvent {
  action: string;
  text: string;
  clearSelection: () => void;
  trigger: Element;
}

export interface CampaignMetaData {
  icon: string;
  type: string;
}

export type VueTranslate = (
  key: string,
  values?: any[] | { [key: string]: any } | undefined,
) => TranslateResult;
export class RecentBillingContact {
  public email: string = '';
  public phone: string = '';
}

export interface BillingPhoneNumber {
  CREATED_AT: Timestamp;
  VALUE: string;
}

export interface BillingContact {
  ID?: string;
  EMAIL: string;
  PHONES: BillingPhoneNumber[];
  CREATED_AT: Date;
}

export type OnlyRequired<T, K extends keyof T> = Partial<T> &
  Required<Pick<T, K>>;

interface LngLat {
  lng: number;
  lat: number;
}

type LngLatLike =
  | LngLat
  | { lng: number; lat: number }
  | { lon: number; lat: number }
  | [number, number];

export interface LocationData {
  ID: string;
  CITY: City;
  BASE_PRICE: number;
  NAME: string;
  DESCRIPTION: string;
  ADDRESS: string;
  COORDINATES: LngLatLike;
  SCREENS_COUNT: number;
  SCREENS_SIZES: string;
  VISITORS_PER_WEEK: number;
  clusterId: string;
  media?: MediaFile;
}

export interface MediaFile {
  name?: string;
  path?: string;
  type?: string;
  mime?: string;
  playBackDuration?: number;
  anonymous?: boolean;
  ref?: firebase.storage.Reference;
  width?: number;
  height?: number;
  unit?: 'px';
  adSpaceId?: string;
  uploadedAt?: string;
  mediaId?: string;
  changeRequestMedia?: MediaFile;
}

export interface DataTableHeaders {
  text: string;
  value: string;
  align?: 'start' | 'center' | 'end';
  sortable?: boolean;
  filterable?: boolean;
  groupable?: boolean;
  divider?: boolean;
  class?: string | string[];
  cellClass?: string | string[];
  width?: string | number;
  filter?: (value: any, search: string, item: any) => boolean;
  sort?: (a: any, b: any) => number;
}
export interface MediaResolution {
  width: number;
  height: number;
  unit: 'px';
}
export interface ScreenResolution {
  WIDTH: number;
  HEIGHT: number;
  UNIT: 'px';
}
export interface CampaignWithSingleAdSpaceMedia extends Campaign {
  mediaFile?: string;
}

export interface DefaultScreenSaver {
  ID?: string;
  MEDIA_LIST: CampaignMedia[];
  ORDER: number;
  ACTIVE: boolean;
}
export enum ScreenServerOrderBy {
  ORDER = 'ORDER',
}
export interface AdSPaceScreenSaver {
  MEDIA_FILE: CampaignMedia;
  ORDER: number;
  ACTIVE: boolean;
}

export interface ShashaAd {
  ID?: string;
  MEDIA_FILE: CampaignMedia;
  NAME: string;
  FREQUENCY: CampaignFrequency;
  AD_SPACE_ID: string;
  START_DATE: Timestamp;
  END_DATE: Timestamp;
  mediaFile?: string;
  companyName: 'Shasha';
  isShashaAd?: true;
  ADMIN_UID: AuthorizedUser['UID'];
}

export interface ScreenSaverItem {
  mediaPreview: string;
  selectedFile: Nullable<File>;
  resolution: ScreenResolution;
}

export interface TimestampCallable {
  _seconds: number;
  seconds: number;
}

export interface PlaylistRequest {
  adSpaceId: string;
  selectedDate: string;
  sortByFrequency: boolean;
  isUserView?: boolean;
}
export type PlaylistAdvertiserCampaign = CampaignWithSingleAdSpaceMedia;

export interface StoreOwnerCampaign {
  ID?: string;
  MEDIA_LIST?: CampaignMedia[];
  ADVERTISER_NAME: string;
  FREQUENCY: CampaignFrequency;
  START_DATE: Timestamp;
  END_DATE: Timestamp;
  DURATION_IN_WEEKS: number;
  SCREEN_CLUSTERS_IDS: string[];
  mediaFile?: string;
  isStoreOwner: boolean;
  PRICE_CONTROL: AdSpacePriceControl;
  companyName?: string;
}
export type PlaylistCampaign =
  PlaylistAdvertiserCampaign | ShashaAd | StoreOwnerCampaign;

export interface CityRecord {
  [geonameId: string]: {
    city: City;
    region: Region;
  };
}

export interface GeoNamesCity {
  geonameId: string;
  name: { en: string; ar: string };
  population: number;
}

export interface GeoNamesRegion {
  geonameId: string;
  name: { en: string; ar: string };
  population: number;
  cities: GeoNamesCity[];
}

export interface GeoNamesCountry {
  geonameId: string;
  countryCode: string;
  countryName: string;
  regions?: GeoNamesRegion[];
  cities?: GeoNamesCity[];
}

export interface GeoNamesData {
  countries: GeoNamesCountry[];
}
