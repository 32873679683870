































































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import UploadFiles from '@/app/shared/components/UploadFiles.vue';

import { DocumentSnapshot } from '@firebase/firestore-types';

import {
  MAXIMUM_PRODUCTS,
  companySizeArray,
  SYSTEM_STATUS,
  SUPPORTED_TYPES,
} from '@/app/shared/utilities/object-factory';
import { CompanyProfile, Country, Nullable } from '@/app/shared/utilities/static-types';
import { getCountriesAction } from '@/app/shared/utilities/geonames-transformer';
import { getUserInfoAction } from '@globalActions/UserRolesActions';

import rules from '@/app/shared/validation-rules';
import CompanyRegViewer from '@/app/shared/components/CompanyRegViewer.vue';
import companyProfileRules from '@company/shared/validation-rules';
import FormComponent from '@/app/shared/components/FormComponent.vue';
import * as actions from '@company/shared/actions';
import { getCurrentUser } from '@/app/shared/firebase/firebase-user';
import firebase from 'firebase';
import storage = firebase.storage;

@Component({
  components: {
    UploadFiles,
    CompanyRegViewer,
    FormComponent,
  },
  data: (vm: any) => ({
    companyNameRules: [rules.required(vm.$i18n.t('field_required'))],
    companyRegistryNameRules: [rules.required(vm.$i18n.t('field_required'))],
    companyRegistrationNumberRules: [
      rules.required(vm.$i18n.t('field_required')),
    ],
    countriesRules: [rules.required(vm.$i18n.t('field_required'))],
    uploadFilesRules: [
      rules.required(vm.$i18n.t('field_required')),
      rules.maximumDocumentSizeInMegabytes(
        5,
        vm.$i18n.t('maximum_allowed_file_size', { maximumSize: 5 }),
      ),
    ],
    companyAddressRules: [rules.required(vm.$i18n.t('field_required'))],
    productsRules: [rules.required(vm.$i18n.t('field_required'))],
  }),
})
export default class CompanyMgmt extends Vue {
  public companyName = '';

  public companyRegistryName = '';

  public companyRegistrationNumber = '';
  public companyRegistrationNumberErrors: string | string[] = [];

  public uploadTaskRef: Nullable<storage.Reference> = null;
  public uploadTask: Nullable<storage.UploadTask> = null;
  public uploadFileErrors: string | string[] = [];
  public uploading = false;
  public isUploadCompleted = false;
  public uploadFilesHint = 'Click to upload files.';
  public companyRegCertLink: Nullable<string> = null;
  public companyRegCertType = '';

  public selectedCountry: Nullable<Country> = null;
  public countryArray: Country[] = [];
  public get countries(): Country[] {
    return this.countryArray!.map(
      (country) =>
        ({
          ...country,
          TEXT: this.$t(`countries.${country.DISPLAY_NAME}`).toString(),
        } as Country),
    );
  }

  public companyAddress = '';

  public productModel: string[] = [];
  public productSearch = null;
  public maxProducts = MAXIMUM_PRODUCTS;

  public vatNumber = '';

  public selectedCompanySize = '';
  public companySizeItems = [...companySizeArray];

  public isReadOnly = false;
  public certificateFileType = false;
  public isCertificateFileFormatValid = false;

  public uploadProgress = 0;

  private certificateFile: Nullable<any> = null;
  private hideUploadField = false;
  private unsubscribeFromCompanyProfile: any;

  public async loadCompanyInfo() {
    try {
      [
        { COMPANY_NAME: this.companyName },
        this.countryArray,
        this.unsubscribeFromCompanyProfile,
      ] = await Promise.all([
        getUserInfoAction(),
        getCountriesAction(),
        actions.subscribeToCompanyProfileAction(this.updateCompanyProfileForm),
      ]);
    } catch (err) {
      throw err;
    }
  }

  public updateCompanyProfileForm(companyProfileDoc: DocumentSnapshot) {
    const loadedProfile = companyProfileDoc.data() as CompanyProfile;
    if (loadedProfile) {
      this.hideUploadField = true;
      this.companyRegistryName = loadedProfile.COMPANY_REGISTRY_NAME;
      this.companyRegistrationNumber =
        loadedProfile.COMPANY_REGISTRATION_NUMBER;
      this.selectedCountry = {
        TEXT: this.$t(
          `countries.${loadedProfile.HEADQUARTER_COUNTRY!.DISPLAY_NAME}`,
        ).toString(),
        ...loadedProfile.HEADQUARTER_COUNTRY,
      };
      this.companyAddress = loadedProfile.COMPANY_ADDRESS;
      this.companyRegCertLink =
        loadedProfile.COMPANY_REGISTRATION_CERTIFICATE.STATIC_LINK;
      this.companyRegCertType =
        loadedProfile.COMPANY_REGISTRATION_CERTIFICATE.CONTENT_TYPE;
      this.productModel = loadedProfile.COMPANY_PRODUCTS;
      this.vatNumber = loadedProfile.COMPANY_VAT!;
      this.selectedCompanySize = loadedProfile.COMPANY_SIZE!;
      const isApprovedProfile =
        loadedProfile.STATUS.VAL === SYSTEM_STATUS.APPROVED.VAL;
      const isDraftProfile =
        loadedProfile.STATUS.VAL === SYSTEM_STATUS.DRAFT.VAL;
      this.isReadOnly = isDraftProfile || isApprovedProfile;
    } else {
      this.hideUploadField = false;
    }
  }

  public async createCompanyProfile() {
    if (!this.companyRegCertLink) {
      throw new Error('Company Registration Certificate is not uploaded.');
    }

    this.isReadOnly = true;

    const currentUser = await getCurrentUser();
    const companyProfile: CompanyProfile = {
      USER_ID: currentUser!.uid,
      COMPANY_NAME: this.companyName,
      COMPANY_REGISTRY_NAME: this.companyRegistryName,
      COMPANY_REGISTRATION_NUMBER: this.companyRegistrationNumber,
      COMPANY_REGISTRATION_CERTIFICATE: {
        CONTENT_TYPE: this.companyRegCertType,
        STATIC_LINK: this.companyRegCertLink,
      },
      HEADQUARTER_COUNTRY: this.selectedCountry!,
      COMPANY_ADDRESS: this.companyAddress,
      COMPANY_PRODUCTS: this.productModel,
      STATUS: SYSTEM_STATUS.DRAFT,
      COMPANY_VAT: this.vatNumber,
      COMPANY_SIZE: this.selectedCompanySize,
    };
    await actions.createCompanyProfileAction(companyProfile);
  }

  public async onUploadStart() {
    try {
      this.uploading = true;
      if (!this.certificateFile) {
        throw new Error('You must select a file to upload.');
      } else if (!this.isCertificateFileFormatValid) {
        throw new Error(this.$t('cert_upload_extension_error').toString());
      }
      this.uploadTaskRef = await actions.uploadRegistrationCertificate(
        this.certificateFile,
      );
      this.uploadTask = this.uploadTaskRef!.put(this.certificateFile);
      this.uploadTask.on(
        storage.TaskEvent.STATE_CHANGED,
        this.fileUploadObserver,
        (error: Error) => {
          throw error;
        },
        this.fileUploadComplete,
      );
    } catch (error) {
      this.uploadFileErrors = error.message;
      this.uploading = false;
    }
  }

  public fileUploadObserver(snapshot: storage.UploadTaskSnapshot) {
    this.uploadProgress =
      (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
  }

  public async fileUploadComplete() {
    this.companyRegCertLink =
      this.uploadTask && (await this.uploadTask.snapshot.ref.getDownloadURL());
    const meta = await this.uploadTaskRef!.getMetadata();
    this.companyRegCertType = meta.contentType!;
    this.isUploadCompleted = true;
    this.uploadFilesHint = this.$t('file_uploaded_successfully').toString();
    this.uploading = false;
  }

  public onFilesPicked(files: any[]) {
    this.isUploadCompleted = false;
    this.uploadFilesHint = '';
    this.uploadProgress = 0;
    this.uploadFileErrors = [];
    const [selectedFile] = files;
    const { type: fileType } = selectedFile;
    this.certificateFile = selectedFile;
    this.certificateFileType = fileType;
    this.isCertificateFileFormatValid =
      fileType === SUPPORTED_TYPES.PNG ||
      fileType === SUPPORTED_TYPES.JPEG ||
      fileType === SUPPORTED_TYPES.PDF;
  }

  @Watch('companyRegistrationNumber')
  public async onCompanyRegistrationNumberChanged(val: string, oldVal: string) {
    this.companyRegistrationNumberErrors = await companyProfileRules.duplicateRegistrationNumber(
      val,
      this.$t('duplicate_company_reg_no').toString(),
    );
  }

  @Watch('productModel')
  public onProductModelChange(val: string[], oldVal: string[]) {
    if (val.length === oldVal.length) {
      return;
    }
    if (val.length > this.maxProducts) {
      this.$nextTick(() => this.productModel.pop());
      return;
    }
  }

  @Watch('$root.$i18n.locale')
  public onChangeLocale(val: string, oldVal: string) {
    const prevSelectedCountry = this.selectedCountry;

    this.countryArray = getCountriesAction();

    if (prevSelectedCountry) {
      this.selectedCountry = this.countries.find(
        (country) => country.GEONAME_ID === prevSelectedCountry.GEONAME_ID,
      )!;
    }
  }

  public async destroyed() {
    this.unsubscribeFromCompanyProfile && this.unsubscribeFromCompanyProfile();
  }
}
