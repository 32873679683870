import {
  AdSpaceBilling,
  Campaign, CampaignFrequency,
  AdSpacePriceControl,
  CampaignMedia,
  CampaignRoutes, FREQUENCY, ScreenResolution,
  StoreContractType,
  SystemStatus,
  UserType,
} from '@/app/shared/utilities/static-types';
import { UserProfileItem, StoreType, DeferredPromise, Promotion } from './static-types';
import firebaseNames from '@/app/shared/utilities/firebase-names';
import { User } from '@firebase/auth-types';
import { isUniqCampaignName } from '@campaigns/shared/actions';
import { FirebaseAppFirestore } from '@/app/shared/firebase/firebase-app';
import routesNames, { AdminRoutes } from './routes-names';
import { RouteConfig } from 'vue-router';

export const campaignDefaultNameFactory = async (user: User | null, lang: 'ar' | 'en', isAdmin: boolean = false) => {
  const userType = isAdmin
    ? firebaseNames.CAMPAIGNS.ADMIN_CAMPAIGNS
    : firebaseNames.CAMPAIGNS.USER_CAMPAIGNS;
  const userCampaignsCount: number = user
  ? (await FirebaseAppFirestore
    .collection(firebaseNames.CAMPAIGNS.VAL)
    .doc(user!.uid)
    .collection(userType)
    .get())
    .docs.length
  : 0;
  let newCampaignNumber = userCampaignsCount + 1;

  switch (lang) {
    case 'ar': {
      let campaignName = `حملة جديدة ${newCampaignNumber}`;
      while (!(await isUniqCampaignName(campaignName))) {
        newCampaignNumber ++;
        campaignName =  `حملة جديدة ${newCampaignNumber}`;
      }
      return campaignName;
      break;
    }

    case 'en': {
      let campaignName = `New Campaign ${newCampaignNumber}`;
      while (!(await isUniqCampaignName(campaignName))) {
        newCampaignNumber ++;
        campaignName =  `New Campaign ${newCampaignNumber}`;
      }
      return campaignName;
      break;
    }
  }
};

export const MAXIMUM_PRODUCTS = 10;

export const USER_POSITIONS = {
  COMPANY_OWNER: {
    DISPLAY_NAME: 'Company Owner',
    VAL: 'company_owner',
  },
  MANAGER: {
    DISPLAY_NAME: 'Manager',
    VAL: 'manager',
  },
  MARKETING_EXECUTIVE: {
    DISPLAY_NAME: 'Marketing Executive',
    VAL: 'marketing_executive',
  },
};

export const companySizeArray = [
  '1-100',
  '100-1000',
  '1000+',
];

export const UPLOAD_STATUS = {
  SELECTED: 'SELECTED',
  UPLOADING: 'UPLOADING',
  UPLOADED: 'UPLOADED',
  ERROR: 'ERROR',
};

export const DATE_FORMATS = {
  STANDARD: 'DD/MM/YYYY',
  DAY_ONLY: 'dddd',
  VERBOSE: 'MMM DD, YYYY',
};

export const SYSTEM_STATUS: Record<string, SystemStatus> = {
  DRAFT: {
    VAL: 'draft',
    COLOR: 'red',
  },
  NEW: {
    VAL: 'new',
    COLOR: 'blue',
  },
  CONNECTED: {
    VAL: 'connected',
    COLOR: 'lime',
  },
  UNDER_PREPARATION: {
    VAL: 'under_preparation',
    COLOR: 'orange',
  },
  PENDING_PAYMENT: {
    VAL: 'pending_payment',
    COLOR: 'lightgrey',
  },
  PENDING_APPROVAL: {
    VAL: 'pending_approval',
    COLOR: 'brown',
  },
  PENDING_CONFIRMATION: {
    VAL: 'pending_confirmation',
    COLOR: 'yellow',
  },
  PENDING_ADMIN_CONFIRMATION: {
    VAL: 'pending_admin_confirmation',
    COLOR: 'yellow darken-5',
  },
  APPROVED: {
    VAL: 'approved',
    COLOR: 'green lighten-3',
  },
  UPCOMING: {
    VAL: 'upcoming',
    COLOR: 'green lighten-3',
  },
  RUNNING: {
    VAL: 'running',
    COLOR: 'light-green darken-2',
  },
  FINISHED: {
    VAL: 'finished',
    COLOR: 'blue-grey darken-4',
  },
  REJECTED: {
    VAL: 'rejected',
    COLOR: 'red lighten-3',
  },
  CANCELED: {
    VAL: 'canceled',
    COLOR: 'red lighten-1',
  },
  REQUIRES_ACTION: {
    VAL: 'requires_action',
    COLOR: 'red lighten-3',
  },
};

export const USER_ROLES = {
  ADMIN: {
    DISPLAY_NAME: 'Administrator',
    VAL: 'admin',
  },
  ADVERTISER: {
    DISPLAY_NAME: 'Advertiser',
    VAL: 'advertiser',
  },
  SCREEN_OWNER: {
    DISPLAY_NAME: 'Screen Owner',
    VAL: 'screen-owner',
  },
  STORE_OWNER: {
    DISPLAY_NAME: 'Store Owner',
    VAL: 'store-owner',
  },
  SALES: {
    DISPLAY_NAME: 'Sales',
    VAL: 'sales',
  },
};

export const SUPPORTED_TYPES = {
  JPEG: 'image/jpeg' as 'image/jpeg',
  GIF: 'image/gif' as 'image/gif',
  PNG: 'image/png' as 'image/png',
  MP4: 'video/mp4' as 'video/mp4',
  PDF: 'application/pdf' as 'application/pdf',
};

export const STORE_TYPES: StoreType[] = [
  {
    DISPLAY_NAME: 'Super Market',
    VAL: 'super-market',
  },
  {
    DISPLAY_NAME: 'Gym (Men)',
    VAL: 'gym-men',
  },
  {
    DISPLAY_NAME: 'Gym (Women)',
    VAL: 'gym-women',
  },
  {
    DISPLAY_NAME: 'Street',
    VAL: 'street',
  },
  {
    DISPLAY_NAME: 'Gas Station',
    VAL: 'gas-station',
  },
  {
    DISPLAY_NAME: 'Cafe',
    VAL: 'cafe',
  },
  {
    DISPLAY_NAME: 'Cinema',
    VAL: 'cinema',
  },
];

export const STORE_PRICE_CONTROL = Object.values(AdSpacePriceControl);

export const STORE_CONTRACT_TYPES: Array<Omit<StoreContractType, 'SHARE'>> = [
  {
    DISPLAY_NAME: 'Revenue Sharing',
    VAL: 'revenue_sharing',
  },
  {
    DISPLAY_NAME: 'Fixed Amount',
    VAL: 'fixed_amount',
  },
  {
    DISPLAY_NAME: 'Rent',
    VAL: 'rent',
  },
];

export const AD_SPACE_BILLING_TYPES: Array<Omit<AdSpaceBilling, 'OWNER_SHARE'>> = [
  {
    DISPLAY_NAME: 'Auto',
    VAL: 'auto',
  },
  {
    DISPLAY_NAME: 'Manual',
    VAL: 'manual',
  },
  {
    DISPLAY_NAME: 'None',
    VAL: 'none',
  },
];

export const COUNTRY_CODES = {
  SAUDI: 'SA',
  BAHRAIN: 'BH',
};

export const UserProfileItemFactory = (
  title: string,
  navigateToPath?: {},
  clickHandler?: (e?: any) => void,
): UserProfileItem => ({
  title,
  navigateToPath,
  clickHandler: clickHandler ? clickHandler : () => { return; },
});

export const Deferred = (): DeferredPromise => ({
  get promise() {
    return new Promise((resolve, reject) => {
      this.resolve = resolve;
      this.reject = reject;
    });
  },
});

export const DB_STORAGE_NAME = 'shasha-platform';
export const DB_STORAGE_VERSION = 1;
export const DB_STORAGE_APP_DATA = 'app-data';
export const DB_STORAGE_APP_LOCALE = 'app-locale';

export const newPromotion = (): Promotion => {
  return {
    NAME: '',
    CODE: '',
    REDEEM_MESSAGE: {
      en: '',
      ar: '',
    },
    PLATFORM_MESSAGE: {
      en: '',
      ar: '',
    },
    PLATFORM_VISIBLE: false,
    START_DATE: new Date().toISOString(),
    END_DATE: new Date().toISOString(),
    TYPE: {
      RATE: 'DISCOUNT',
      VALUE: 0,
    },
    APPLICABLE_SCREEN_CLUSTER_IDS: [],
    FIRST_PUBLISHED_CAMPAIGN_ONLY: true,
    MINIMUM_NUMBER_OF_WEEKS_IN_CAMPAIGN: null,
  } as Promotion;
};

const campaignRoutesFactory = (campaign: Campaign): CampaignRoutes => {
  // tslint:disable-next-line:variable-name
  const under_preparation = campaign.CREATED_BY_STORE_OWNER
    ? AdminRoutes.STORE_CAMPAIGN_REVIEW
    : AdminRoutes.ADVERTISER_CAMPAIGN_REVIEW;
  return {
    admin: {
      pending_payment: AdminRoutes.ADVERTISER_CAMPAIGN_REVIEW,
      under_preparation,
    },
    storeOwner: {
      pending_approval: routesNames.CAMPAIGN_REVIEW,
      new: routesNames.STORE_CAMPAIGN_EDIT,
      rejected: routesNames.STORE_CAMPAIGN_EDIT,
    },
    advertiser: {
      new: routesNames.CAMPAIGN_EDIT,
      rejected: routesNames.CAMPAIGN_EDIT,
    },
  };
};

export const campaignNavigation = (userType: UserType, campaign: Campaign): RouteConfig | undefined => {
  const campaignRoutes = campaignRoutesFactory(campaign);
  const { STATUS: { VAL: campaignStatus } } = campaign;
  return campaignRoutes[userType][campaignStatus];
};
export const convertFromMediaResolutionTypeToScreenResolutionType = (screenResolution: ScreenResolution) => {
  return {
    width: screenResolution.WIDTH,
    height: screenResolution.HEIGHT,
    unit: screenResolution.UNIT,
  };

};
export const getMediaName = (
  {
    userTypeAbbreviation,
    companyName,
    campaignName,
    mediaId,
    extension,
  }: {
    userTypeAbbreviation: string,
    companyName: string,
    campaignName?: string,
    mediaId: string,
    extension: string,
  },
) => {
  const campaignNameShow = `${campaignName ? `${campaignName}--` : ''}`;
  return `${userTypeAbbreviation}--${companyName}--${campaignNameShow}${mediaId}.${extension}`;

};
const COST_SCALING_FACTOR = 0.7;

export const campaignFrequencies: Record<FREQUENCY, CampaignFrequency> = {
  [FREQUENCY['1/2X']]: {
    NAME: FREQUENCY['1/2X'],
    COST_FACTOR: COST_SCALING_FACTOR,
    MINUTES: 4,
    LABEL: 'saver',
  },
  [FREQUENCY['1X']]: {
    NAME: FREQUENCY['1X'],
    COST_FACTOR: 1,
    MINUTES: 2,
    LABEL: 'regular',
  },
  [FREQUENCY['2X']]: {
    NAME: FREQUENCY['2X'],
    COST_FACTOR: 1 / COST_SCALING_FACTOR,
    MINUTES: 1,
    LABEL: 'premium',
  },
};
export const FILTER_QUERY_LIMIT = 10;

export const USER_TYPES_ABBREVIATION = {
  [USER_ROLES.ADVERTISER.VAL]: 'adv',
  [USER_ROLES.STORE_OWNER.VAL]: 'sto',
  [USER_ROLES.ADMIN.VAL]: 'adm',
  shasha: 'sha',
};

/*
  Get the media key using the width and height; to be used in the media map.
* */
export const getMediaKeyByResolution = (media: CampaignMedia): string => {
  return media!.WIDTH + '*' + media!.HEIGHT;
};

export const STORE_TYPES_ICON = {
  'super-market': '/img/icons/supermarket.svg',

  'gym-men': '/img/icons/gym-men.svg',

  'gym-women': '/img/icons/gym-women.svg',

  'street': '/img/icons/street.svg',

  'gas-station': '/img/icons/gas-station.svg',

  'cafe': '/img/icons/cafe.svg',

  'cinema': '/img/icons/cinema.svg',

  'mall': '/img/icons/mall.svg',

};
