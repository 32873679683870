




















































































































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator';

import {
  Country,
  City,
  StoreAccount,
  AdSpace,
  AdSpaceScreensPosition,
  AdSPaceScreenSaver,
  AdSpacePriceControl,
  RegulatorAccount,
  Region,
} from '@/app/shared/utilities/static-types';
import { STORE_PRICE_CONTROL, STORE_TYPES } from '@/app/shared/utilities/object-factory';
import {
  getStoresAction,
} from '@globalActions/DevicesActions';
import {
  getCountriesAction,
  getCitiesByCountryCodeAction,
} from '@/app/shared/utilities/geonames-transformer';
import rules from '@/app/shared/validation-rules';
import { AdminRoutes } from '@/app/shared/utilities/routes-names';
import FormComponent from '@/app/shared/components/FormComponent.vue';
import {
  updateAdSpaceAction,
  loadAdSpaceByIdAction,
} from '@adSpaces/shared/actions';
import { isAdminUserAction, isStoreOwnerAction } from '@globalActions/UserRolesActions';
import AdSpaceMediaUpload from '@adSpaces/screens/StoreOwner/screens/components/AdSpaceMediaUpload.vue';
import { getBlobFromImageSrc } from '@/app/shared/utilities/helper-functions';
import CreateAdSpaceScreenSaver from '@adSpaces/screens/StoreOwner/screens/AdSpaceNew/Components/CreateAdSpaceScreenSaver/CreateAdSpaceScreenSaver.vue';
import AdSpaceScreenSaverList from '@adSpaces/screens/StoreOwner/screens/AdSpaceNew/Components/AdSpaceScreenSaverList/AdSpaceScreenSaverList.vue';
import { loadRegulators } from '@adminStores/shared/actions';
import { getRegionByCityGeonameId } from '@/app/shared/utilities/geonames-transformer';

@Component({
  components: {
    AdSpaceMediaUpload,
    FormComponent,
    CreateAdSpaceScreenSaver,
    AdSpaceScreenSaverList,
  },
  data: (vm: any) => ({
    requiredRules: [rules.required(vm.$i18n.t('field_required'))],
    adSpaceScreensPositions: Object.values(AdSpaceScreensPosition).map((val) => ({
      VAL: val,
      TEXT:  vm.$i18n.t(val),
    })),
  }),
})
export default class AdSpaceEdit extends Vue {
  public loading = true;
  public allAdSpacesRoute = AdminRoutes.ALL_AD_SPACES;
  public storePriceControl = STORE_PRICE_CONTROL;
  public regulators: RegulatorAccount[] = [];
  public regulator: RegulatorAccount | null = null;
  public storeTypes = STORE_TYPES;
  public countryArray: Country[] = [];
  public cityArray: City[] = [];
  public showCreateAdSpaceScreenSaver = false;
  public adSpace: AdSpace = {
    NAME: '',
    TYPE: {
      DISPLAY_NAME: 'Super Market',
      VAL: 'super-market',
    },
    COORDINATES: {},
    SCREENS_RESOLUTION: {
      UNIT: 'px',
    },
    SCREEN_SAVERS: [] as AdSPaceScreenSaver[],
    PRICE_CONTROL: AdSpacePriceControl.BY_SHASHA,
  } as unknown as AdSpace;

  public selectedMediaFiles: File[] = [];

  public loadCountries = getCountriesAction;

  public storeAccounts: StoreAccount[] = [];

  public isAdminUser = false;
  public isStoreOwner = false;

  public get countries(): Country[] {
    return this.countryArray.map(
      (country) =>
        ({
          ...country,
          TEXT: this.$t(`countries.${country.DISPLAY_NAME}`).toString(),
        } as Country),
    );
  }

  public get cities(): City[] {
    return this.cityArray!.map(
      (city) =>
        ({
          ...city,
          TEXT: this.$t(`cities.${city.DISPLAY_NAME.en}`).toString(),
        } as City),
    );
  }

  public updateRegulator(regulatorId: string) {
    return this.regulator = this.regulators.find((regulator) => regulator.UID === regulatorId) || null;
  }

  public async loadData() {
    this.loading = true;
    this.adSpace = { ...this.adSpace, ...(await this.loadAdSpace()) };
    try {
      this.selectedMediaFiles = await Promise.all(
          this.adSpace.MEDIA
              ? this.adSpace.MEDIA.map(({ PATH }) => getBlobFromImageSrc(PATH))
              : this.adSpace.MEDIA_FILE
                  ? [getBlobFromImageSrc(this.adSpace.MEDIA_FILE!)]
                  : [],
      );
    } catch (e) {
      // tslint:disable-next-line:no-console
      console.log(e);
    }
    this.loadCities(this.adSpace.COUNTRY);
    this.countryArray = this.loadCountries();
    this.regulators = await loadRegulators();
    this.updateRegulator(this.adSpace.REGULATOR_ID);
    this.isAdminUser = await isAdminUserAction();
    this.isStoreOwner = await isStoreOwnerAction();
    this.storeAccounts = await getStoresAction();
    this.loading = false;
  }

  public loadCities(country: Country) {
    this.cityArray = getCitiesByCountryCodeAction(country);
  }

  public async loadAdSpace() {
    try {
      this.loading = true;
      const loadedId = this.$route.params.id;
      if (!loadedId) {
        this.$router.push(this.allAdSpacesRoute);
        throw new Error('Error loading adspace.');
      }

      return loadAdSpaceByIdAction(loadedId);
    } finally {
      this.loading = false;
    }
  }

  public async updateAdSpace() {
    await updateAdSpaceAction(this.adSpace);
    this.$router.push(this.allAdSpacesRoute);
  }

  @Watch('$root.$i18n.locale')
  public onChangeLocale(val: string, oldVal: string) {
    const [prevSelectedCountry, prevSelectedCity] = [
      this.adSpace.COUNTRY,
      this.adSpace.CITY,
    ];

    this.countryArray = this.loadCountries();

    if (prevSelectedCountry) {
      this.adSpace.COUNTRY = this.countries.find(
        (country) => country.GEONAME_ID === prevSelectedCountry.GEONAME_ID,
      )!;
      this.loadCities(prevSelectedCountry);
    }

    if (prevSelectedCity) {
      this.adSpace.CITY = this.cities.find(
        (city) => city.GEONAME_ID === prevSelectedCity.GEONAME_ID,
      )!;
    }
  }
  public adSpaceScreenSaverCreated(adSpaceScreenSaver: AdSPaceScreenSaver) {
    this.adSpace.SCREEN_SAVERS = this.adSpace.SCREEN_SAVERS.concat(adSpaceScreenSaver);
  }
  public reorderMapper(adSpaceScreenSaver: AdSPaceScreenSaver, index: number) {
    adSpaceScreenSaver.ORDER = index + 1;
    return adSpaceScreenSaver;
  }
  public adSpaceScreenSaverDeleted(screenSaverMediaPath: string) {
    this.adSpace.SCREEN_SAVERS = this.adSpace.SCREEN_SAVERS
        .filter((adSpaceScreenSaver) => adSpaceScreenSaver.MEDIA_FILE.MEDIA_FILE !== screenSaverMediaPath)
        .map(this.reorderMapper);
  }
  public adSpaceScreenSaverReordered(adSpaceScreenSavers: AdSPaceScreenSaver[]) {
    this.adSpace.SCREEN_SAVERS = adSpaceScreenSavers.map(this.reorderMapper);
  }

  @Watch('adSpace.COUNTRY')
  public countryChanged() {
    this.adSpace.REGION = {} as Region;
  }

  @Watch('adSpace.CITY')
  public cityChanged(newData: City, oldData: City) {
    if (!this.adSpace.CITY) {
      return;
    }
    if (!oldData || oldData.GEONAME_ID !== this.adSpace.CITY.GEONAME_ID) {
      const region = getRegionByCityGeonameId(newData.GEONAME_ID);
      this.adSpace.CITY = newData;
      this.adSpace.REGION = region as Region;
    }
  }
}
