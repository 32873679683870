
































import { Component, Vue, Watch } from 'vue-property-decorator';

import {
  StoreType,
  City,
  StoreAccount,
  Country,
  Nullable, StoreContractType, RegulatorAccount,
} from '@/app/shared/utilities/static-types';

import {
  loadRegulatorAccountAction,
  updateRegulatorAccountAction,
} from '@adminStores/shared/actions';
import FormComponent from '@/app/shared/components/FormComponent.vue';
import storeRules from '@adminStores/shared/validation-rules';
import rules from '@/app/shared/validation-rules';

@Component({
  components: {
    FormComponent,
  },
  data: (vm: any) => ({
    emailRules: [
      rules.required(vm.$i18n.t('field_required')),
      rules.emailFormat(vm.$i18n.t('invalid_email')),
    ],
    nameRules: [
      rules.required(vm.$i18n.t('field_required')),
    ],
    contactPhoneRules: [
      rules.saudiPhoneFormat(vm.$i18n.t('phone_not_saudi_valid')),
    ],
    contactEmailRules: [
      rules.emailFormat(vm.$i18n.t('invalid_email')),
    ],
  }),
})
export default class EditRegulator extends Vue {
  public regulatorAccount: Nullable<RegulatorAccount> = null;
  public name = '';
  public email = '';



  public contactName: Nullable<string> = '';
  public contactNameRules = [];

  public contactPhone: Nullable<string> = '';

  public contactEmail: Nullable<string> = '';

  public async initForm() {
    await this.loadStoreAccount();
  }

  public get regulatorAccountId() {
    return this.$route.params.id;
  }

  public async loadStoreAccount() {
    this.regulatorAccount = await loadRegulatorAccountAction(this.regulatorAccountId);

    if (!this.regulatorAccount) {
      this.$router.back();
      return;
    }


    if (this.regulatorAccount.CONTACT_PERSON) {
      this.contactName = this.regulatorAccount.CONTACT_PERSON!.NAME;
      this.contactPhone = this.regulatorAccount.CONTACT_PERSON!.PHONE;
      this.contactEmail = this.regulatorAccount.CONTACT_PERSON!.EMAIL;
    }


    this.email = this.regulatorAccount.EMAIL;
    this.name = this.regulatorAccount.NAME || '';
  }

  public async updateRegulatorAccount() {
    const regulatorAccount: RegulatorAccount = {
      ...this.regulatorAccount,
      NAME: this.name,
      EMAIL: this.email,
      CONTACT_PERSON: {
        NAME: this.contactName || '',
        PHONE: this.contactPhone || '',
        EMAIL: this.contactEmail || '',
      },
    };

    await updateRegulatorAccountAction(regulatorAccount);
    this.$router.back();
  }
}
