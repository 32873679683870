


























import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { AdSpace, MultiLanguage } from '@/app/shared/utilities/static-types';
import ClickableListItem from '@/app/screens/App/shared/components/ClickableListItem.vue';

@Component({
  components: {
    ClickableListItem,
  },
})
export default class ClickableAdspaceItem extends Vue {
  @Prop({ required: true }) public adSpace!: AdSpace;
  @Prop({ default: '' }) public storeName!: string;
  @Prop({ default: null }) public navigateTo!: { name: string };
  @Prop({}) public toggleVisibilityLoading!: boolean;

  public toggledAdSpaceId: string = '';

  public goToEditAdSpace(id: string) {
    this.$router.push({
      ...this.navigateTo,
      params: {
        id,
      },
    });
  }

  public get adSpaceInfo() {
    const city = this.$t(`cities.${(this.adSpace.CITY.DISPLAY_NAME as unknown as MultiLanguage).en || this.adSpace.CITY.DISPLAY_NAME!}`);
    const country = this.$t(`countries.${this.adSpace.COUNTRY.DISPLAY_NAME!}`);
    const address = this.adSpace.ADDRESS;
    return `${city}, ${country}, ${address}`;
  }

  public get isLoading() {
    return this.adSpace.ID === this.toggledAdSpaceId && this.toggleVisibilityLoading;
  }

  public async toggleVisibleOnPlatform(event: Event, adSpace: AdSpace) {
    event.stopPropagation();
    this.toggledAdSpaceId = adSpace.ID;
    this.$emit('toggled-ad-space-visibility', adSpace);
  }

  @Watch('toggleVisibilityLoading')
  public toggleVisibilityLoadingChanged() {
    if (!this.toggleVisibilityLoading) {
      this.toggledAdSpaceId = '';
    }
  }
}
